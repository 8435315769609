import { Component, OnInit } from '@angular/core'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  constructor(breadcrumbService: BreadcrumbService, private router: Router) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Erreur 404 : Page non trouvée'
      }
    ])
  }

  ngOnInit() {
    // No 404 on embedded app, we redirect to home.
    if (environment.nativescriptEmbedded) {
      this.router.navigate(['/'])
    }
  }
}
