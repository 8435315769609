import { Component, OnInit } from '@angular/core'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { BreadcrumbLink } from '../../common/interfaces/breadcrumb-link.interface'

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbLinks: BreadcrumbLink[]

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    this.breadcrumbService.breadcrumbLinks.subscribe(
      (newValue: BreadcrumbLink[]) => {
        this.breadcrumbLinks = newValue
      }
    )
  }
}
