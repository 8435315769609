import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker'

export class HasDatepicker {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    height: '45px',
    selectorWidth: '310px',
    selectorHeight: '312px'
  }

  // Format YYYY-MM-DD to MyDatePicker format (today if date not specified)
  formatStandardDate(
    dateString = new Date().toISOString().substring(0, 10)
  ): { date: IMyDate } {
    // In case of NULL dateString (!! Different than empty), we return null to make datepicker empty
    if (!dateString) {
      return null
    }
    const splittedDateFrom = dateString.split('-')
    return {
      date: {
        year: parseInt(splittedDateFrom[0], 10),
        month: parseInt(splittedDateFrom[1], 10),
        day: parseInt(splittedDateFrom[2], 10)
      }
    }
  }

  // Format MyDatePicker format date to YYYY-MM-DD
  formatMyDatePickerDate(dateObject: IMyDateModel): string {
    return `${dateObject.date.year}-${dateObject.date.month
      .toString()
      .padStart(2, '0')}-${dateObject.date.day.toString().padStart(2, '0')}`
  }
}
