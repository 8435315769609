import { Component, Input } from '@angular/core'
import { Subcategory } from '../../../common/interfaces/subcategory.interface'

@Component({
  selector: 'app-subcategory-table',
  templateUrl: './subcategory-table.component.html',
  styleUrls: ['./subcategory-table.component.scss']
})
export class SubcategoryTableComponent {
  @Input() subcategories: Subcategory[]
  subcategoryToDelete: Subcategory
}
