import { Component, OnInit } from '@angular/core'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpParams } from '@angular/common/http'
import { FormBuilder, FormGroup } from '@angular/forms'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  paginator: Paginator
  filterForm: FormGroup = this.formBuilder.group({
    categoryIds: []
  })
  loading: boolean

  initialSearchResults: { categoryIds: string[] } = {
    categoryIds: []
  }

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Catégories'
      }
    ])
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      let params = new HttpParams()
      params = params.set('page', queryParams.page ? queryParams.page : 1)

      // Categories
      if (queryParams.categoryIds && queryParams.categoryIds.length) {
        this.initialSearchResults.categoryIds = []

        // If only one item (string), we convert it into a single-item array
        const categoryIds =
          typeof queryParams.categoryIds === 'string'
            ? [queryParams.categoryIds]
            : queryParams.categoryIds
        categoryIds.forEach((categoryId: string) => {
          params = params.append('categoryIds', categoryId)
          this.initialSearchResults.categoryIds.push(categoryId)
          this.filterForm.get('categoryIds').setValue(categoryIds)
        })
      }

      this.loading = true
      this.resourceService.list('categories', params).subscribe(
        (categoryRes: Paginator) => {
          this.loading = false
          this.paginator = categoryRes
        },
        (error) => {
          this.loading = false
          this.flashMessagesService.show(
            'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    })

    // Reload component on changing filterForm
    this.filterForm.valueChanges.subscribe((params) => {
      this.router.navigate(['/categories'], {
        queryParams: params,
        queryParamsHandling: 'merge'
      })
    })
  }

  onSelectedSearchResultsChanged(searchResults: SearchResult[]) {
    this.filterForm
      .get('categoryIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'categories')
          .map((s: SearchResult) => s.id.toString())
      )
  }
}
