import { Component, OnInit } from '@angular/core'
import { Mission } from '../../../common/interfaces/mission.interface'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Router } from '@angular/router'
import { HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-sample-pre-create',
  templateUrl: './sample-pre-create.component.html',
  styleUrls: ['./sample-pre-create.component.scss']
})
export class SamplePreCreateComponent implements OnInit {
  missions: Mission[]

  form: FormGroup = this.formBuilder.group({
    missionId: [null, Validators.required]
  })

  constructor(
    breadcrumbService: BreadcrumbService,
    private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Nouveau prélèvement'
      }
    ])
  }

  ngOnInit() {
    let params = new HttpParams()
    params = params.append('withoutPagination', 'true')

    this.resourceService
      .list('missions', params)
      .subscribe((res: Mission[]) => {
        this.missions = res
      })
  }

  createSample(form: FormGroup): void {
    this.router.navigate([
      '/missions',
      form.value.missionId,
      'samples',
      'create'
    ])
  }
}
