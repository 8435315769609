import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { FlashMessagesService } from 'angular2-flash-messages'
import { User } from '../../../common/interfaces/user.interface'
import { appConstants } from '../../../app.constants'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required]
  })

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private flashMessagesService: FlashMessagesService,
    private router: Router
  ) {}

  submitForm(loginForm: FormGroup) {
    this.authService
      .login(loginForm.value.email, loginForm.value.password)
      .subscribe(
        (res: { role: string; userId: number }) => {
          return this.router.navigate(['/'])
        },
        err => {
          const message: string = err.error.message
          this.flashMessagesService.show(
            message ||
              `Impossible de se connecter à l'application. Vérifiez que vous avez bien accès à internet.`,
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
          this.loginForm.reset()
        }
      )
  }
}
