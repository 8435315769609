import { Component, Input, OnChanges, OnInit } from '@angular/core'
import * as moment from 'moment'

import { Role } from '../../common/enums/role.enum'
import { AuthService } from '../../common/services/auth.service'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnChanges {
  @Input() path: string
  role: Role
  sampleDateFrom: string
  sampleDateTo: string = moment().format('YYYY-MM-DD')

  activeItem: string

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.role = this.authService.getRole()

    if (this.role === Role.Admin) {
      this.sampleDateFrom = moment()
        .subtract(1, 'week')
        .format('YYYY-MM-DD')
    } else {
      this.sampleDateFrom = moment()
        .startOf('month')
        .format('YYYY-MM-DD')
    }
  }

  ngOnChanges() {
    if (this.path.includes('/samples')) {
      this.activeItem = 'samples'
    } else if (this.path.includes('/subcategories')) {
      this.activeItem = 'subcategories'
    } else if (this.path.includes('/categories')) {
      this.activeItem = 'categories'
    } else if (this.path.includes('/users')) {
      this.activeItem = 'users'
    } else if (this.path.includes('/missions')) {
      this.activeItem = 'missions'
    }
  }
}
