import { Injectable } from '@angular/core'
import { Observable, fromEvent, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import { Sample } from '../interfaces/sample.interface'

// * Keeps last sample on localStorage and returns it if no network.
@Injectable({
  providedIn: 'root'
})
export class SampleService {
  isOnline = true

  constructor(private http: HttpClient) {
    fromEvent(window, 'offline').subscribe(e => {
      this.isOnline = false
    })

    fromEvent(window, 'online').subscribe(e => {
      this.isOnline = true
    })
  }

  show(id: number | string): Observable<Sample> {
    if (!this.isOnline) {
      // If no network, we can still return last sample fetched.
      const lastSample: Sample = JSON.parse(localStorage.getItem('lastSample'))

      if (lastSample.id.toString() === id) {
        return of(lastSample)
      }
    }

    return this.http.get(`${environment.apiBaseUrl}samples/${id}`).pipe(
      map((res: Sample) => {
        localStorage.setItem('lastSample', JSON.stringify(res))
        return res
      })
    )
  }
}
