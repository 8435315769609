import { Component, OnInit, Input } from '@angular/core'
import { Mission } from '../../../common/interfaces/mission.interface'
import { Sample } from '../../../common/interfaces/sample.interface'
import { User } from '../../../common/interfaces/user.interface'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-mission-table-touch',
  templateUrl: './mission-table-touch.component.html',
  styleUrls: ['./mission-table-touch.component.scss']
})
export class MissionTableTouchComponent implements OnInit {
  @Input() missions: Mission[]
  missionToDelete: Mission

  currentUser: User
  Role = Role
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.missions.forEach((m: Mission) => {
      m.weighingCount = m.samples.reduce(
        (previous: number, current: Sample) => previous + current.weighingCount,
        0
      )
    })

    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })
  }
}
