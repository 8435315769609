import { Injectable } from '@angular/core'
import { HttpParams, HttpClient } from '@angular/common/http'
import { map, retryWhen, mapTo, switchMap } from 'rxjs/operators'
import {
  Observable,
  throwError,
  fromEvent,
  MonoTypeOperatorFunction
} from 'rxjs'

import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private onlineChanges$: Observable<boolean> = fromEvent(
    window,
    'online'
  ).pipe(mapTo(true))

  get isOnline() {
    return navigator.onLine
  }

  constructor(private http: HttpClient) {}

  list(resourceName: string, params?: HttpParams): Observable<any> {
    return this.http
      .get(`${environment.apiBaseUrl}${resourceName}`, {
        params
      })
      .pipe(map((res) => res))
  }

  show(
    resourceName: string,
    id: number | string,
    suffix?: string
  ): Observable<any> {
    return this.http
      .get(
        `${environment.apiBaseUrl}${resourceName}/${id}` +
          (suffix ? `/${suffix}` : '')
      )
      .pipe(
        map((res) => {
          return res
        })
      )
  }

  store(resourceName: string, formData: FormData): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}${resourceName}`, formData)
      .pipe(
        this.retryWhenOnline(),
        map((response) => {
          return response
        })
      )
  }

  // Throws directly an error if the POST request fails because of no network.
  storeAndFailIfOffline(
    resourceName: string,
    formData: FormData
  ): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}${resourceName}`, formData)
      .pipe(
        map((response) => {
          return response
        })
      )
  }

  update(
    resourceName: string,
    id: number | string,
    formData: FormData
  ): Observable<any> {
    return this.http
      .put(`${environment.apiBaseUrl}${resourceName}/${id}`, formData)
      .pipe(
        this.retryWhenOnline(),
        map((res) => {
          return res
        })
      )
  }

  patch(
    resourceName: string,
    id: number | string,
    suffix: string,
    formData: FormData
  ): Observable<any> {
    return this.http
      .patch(
        `${environment.apiBaseUrl}${resourceName}/${id}/${suffix}`,
        formData
      )
      .pipe(
        this.retryWhenOnline(),
        map((res) => {
          return res
        })
      )
  }

  delete(resourceName: string, id: number | string): Observable<any> {
    return this.http
      .delete(`${environment.apiBaseUrl}${resourceName}/${id}`)
      .pipe(
        this.retryWhenOnline(),
        map((res) => {
          return res
        })
      )
  }

  // POST, PUT, PATCH and DELETE requests are blocked if offline until network is not back.
  private retryWhenOnline(): MonoTypeOperatorFunction<unknown> {
    return retryWhen((errors) => {
      if (this.isOnline) {
        return errors.pipe(switchMap((err) => throwError(err)))
      }
      return this.onlineChanges$
    })
  }
}
