import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'

import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { Role } from '../../../common/enums/role.enum'
import { User } from '../../../common/interfaces/user.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-user-create-edit',
  templateUrl: './user-create-edit.component.html',
  styleUrls: ['./user-create-edit.component.scss'],
  providers: [ResourceService]
})
export class UserCreateEditComponent implements OnInit {
  user: User
  userToDelete: User
  mode: string
  Role = Role
  loading: boolean
  submitLoading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: '',
    role: [null, [Validators.required]],
    hourlyRate: '',
    minTurnover: '',
    objective: ''
  })

  image: { name: string; content: any }
  storagePath: string = environment.storagePath

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    if (this.mode === 'create') {
      // Password required only on create mode
      this.form.get('password').setValidators(Validators.required)
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/users',
          label: 'Utilisateurs'
        },
        {
          label: 'Nouvel utilisateur'
        }
      ])
    } else {
      this.loading = true
      this.resourceService
        .show('users', this.activatedRoute.snapshot.params.id)
        .subscribe(
          (userRes: User) => {
            this.loading = false
            this.user = userRes

            this.form.controls.name.setValue(userRes.name)
            this.form.controls.email.setValue(userRes.email)
            this.form.controls.password.setValue(userRes.password)
            this.form.controls.role.setValue(userRes.role)

            // Set breadcrumbs
            this.breadcrumbService.breadcrumbLinks.next([
              {
                path: '/users',
                label: 'Utilisateurs'
              },
              {
                label: this.user.name
              }
            ])
          },
          error => {
            this.loading = false
            this.flashMessagesService.show(
              'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  submit(form: FormGroup) {
    const formData = new FormData()
    formData.append('name', form.value.name)
    formData.append('email', form.value.email)
    formData.append('role', form.value.role)

    if (form.value.password) {
      formData.append('password', form.value.password)
    }

    if (this.image) {
      formData.append('image', this.image.content, this.image.name)
    } else {
      formData.append('image', '')
    }

    this.submitLoading = true
    if (this.mode === 'create') {
      this.resourceService.store('users', formData).subscribe(
        res => {
          this.submitLoading = false
          this.flashMessagesService.show(`L'utilisateur a bien été créé`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          this.router.navigate(['/users'])
        },
        err => {
          this.submitLoading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Update existing resource
      this.resourceService.update('users', this.user.id, formData).subscribe(
        res => {
          this.submitLoading = false
          this.flashMessagesService.show(
            `L'utilisateur a bien été mis à jour`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
          this.router.navigate(['/users'])
        },
        err => {
          this.submitLoading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    }
  }

  delete(user: User) {
    this.userToDelete = user
  }
  onDeleteCanceled() {
    delete this.userToDelete
  }
}
