import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpParams } from '@angular/common/http'

import { ResourceService } from '../../../common/services/resource.service'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { environment } from '../../../../environments/environment'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [ResourceService]
})
export class UserListComponent implements OnInit {
  paginator: Paginator
  loading: boolean

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        path: '/users',
        label: 'Utilisateurs'
      }
    ])
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      // Create request params and set actual params (in case of reload)
      let params = new HttpParams()
      params = params.set('page', queryParams.page ? queryParams.page : 1)
      params = params.set('includeNotActive', 'true')

      if (queryParams.toXLS) {
        params = params.set('toXLS', 'true')
        this.resourceService
          .list('users', params)
          .subscribe((res: { filePath: string }) => {
            window.open(environment.storagePath + res.filePath)
            const exportParams: any = {}
            exportParams.toXLS = null
            this.router.navigate(['/users'], {
              queryParams: exportParams,
              queryParamsHandling: 'merge'
            })
          })
      } else {
        this.loading = true
        this.resourceService.list('users', params).subscribe(
          (userRes: Paginator) => {
            this.loading = false
            this.paginator = userRes
          },
          error => {
            this.loading = false
            this.flashMessagesService.show(
              'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
      }
    })
  }
}
