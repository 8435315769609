import { Component, OnInit } from '@angular/core'
import { fromEvent } from 'rxjs'

import { appConstants } from '../../app.constants'

@Component({
  selector: 'app-network-notification',
  templateUrl: './network-notification.component.html',
  styleUrls: ['./network-notification.component.scss']
})
export class NetworkNotificationComponent implements OnInit {
  isOnline = true
  displayIsOnline: boolean

  ngOnInit() {
    fromEvent(window, 'offline').subscribe(e => {
      this.isOnline = false
    })

    fromEvent(window, 'online').subscribe(e => {
      this.isOnline = true

      this.displayIsOnline = true
      setTimeout(() => {
        this.displayIsOnline = false
      }, appConstants.FLASH_MESSAGE_TIMEOUT)
    })
  }
}
