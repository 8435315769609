import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpParams } from '@angular/common/http'
import { FormBuilder, FormGroup } from '@angular/forms'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { User } from '../../../common/interfaces/user.interface'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { AuthService } from '../../../common/services/auth.service'
import { Role } from '../../../common/enums/role.enum'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-sample-list',
  templateUrl: './sample-list.component.html',
  styleUrls: ['./sample-list.component.scss'],
  providers: [ResourceService]
})
export class SampleListComponent extends HasDatepicker implements OnInit {
  paginator: Paginator
  users: User[]
  currentUser: User
  Role = Role
  loading: boolean

  filterForm: FormGroup = this.formBuilder.group({
    sampleIds: [],
    missionIds: [],
    userId: [],
    dateFrom: null,
    dateTo: null
  })

  initialSearchResults: { sampleIds: string[]; missionIds: string[] } = {
    sampleIds: [],
    missionIds: []
  }

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private flashMessagesService: FlashMessagesService,
    breadcrumbService: BreadcrumbService
  ) {
    super()
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Prélèvements'
      }
    ])
  }

  ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })

    this.activatedRoute.queryParams.subscribe(queryParams => {
      let params = new HttpParams()
      params = params.set('page', queryParams.page ? queryParams.page : 1)

      if (queryParams.userId) {
        params = params.set('userId', queryParams.userId)
        this.filterForm.controls.userId.setValue(queryParams.userId)
      }

      if (queryParams.dateFrom) {
        params = params.set('dateFrom', queryParams.dateFrom)
        this.filterForm.controls.dateFrom.setValue(
          this.formatStandardDate(queryParams.dateFrom)
        )
      }
      if (queryParams.dateTo) {
        params = params.set('dateTo', queryParams.dateTo)
        this.filterForm.controls.dateTo.setValue(
          this.formatStandardDate(queryParams.dateTo)
        )
      }

      // Samples
      if (queryParams.sampleIds && queryParams.sampleIds.length) {
        this.initialSearchResults.sampleIds = []

        // If only one item (string), we convert it into a single-item array
        const sampleIds =
          typeof queryParams.sampleIds === 'string'
            ? [queryParams.sampleIds]
            : queryParams.sampleIds
        sampleIds.forEach((sampleId: string) => {
          params = params.append('sampleIds', sampleId)
          this.initialSearchResults.sampleIds.push(sampleId)
          this.filterForm.get('sampleIds').setValue(sampleIds)
        })
      }

      // Missions
      if (queryParams.missionIds && queryParams.missionIds.length) {
        this.initialSearchResults.missionIds = []

        // If only one item (string), we convert it into a single-item array
        const missionIds =
          typeof queryParams.missionIds === 'string'
            ? [queryParams.missionIds]
            : queryParams.missionIds
        missionIds.forEach((missionId: string) => {
          params = params.append('missionIds', missionId)
          this.initialSearchResults.missionIds.push(missionId)
          this.filterForm.get('missionIds').setValue(missionIds)
        })
      }

      this.loading = true
      this.resourceService.list('samples', params).subscribe(
        (sampleRes: Paginator) => {
          this.loading = false
          this.paginator = sampleRes
        },
        error => {
          this.loading = false
          this.flashMessagesService.show(
            'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    })

    let userListParams = new HttpParams()
    userListParams = userListParams.set('withoutPagination', 'true')

    this.resourceService
      .list('users', userListParams)
      .subscribe((userRes: User[]) => {
        this.users = userRes
      })

    // Reload component on changing filterForm
    this.filterForm.valueChanges.subscribe(params => {
      if (params.dateFrom) {
        params.dateFrom = this.formatMyDatePickerDate(params.dateFrom)
      }

      if (params.dateTo) {
        params.dateTo = this.formatMyDatePickerDate(params.dateTo)
      }

      this.router.navigate(['/samples'], {
        queryParams: params,
        queryParamsHandling: 'merge'
      })
    })
  }

  onSelectedSearchResultsChanged(searchResults: SearchResult[]) {
    this.filterForm
      .get('sampleIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'samples')
          .map((s: SearchResult) => s.id.toString())
      )

    this.filterForm
      .get('missionIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'missions')
          .map((s: SearchResult) => s.id.toString())
      )
  }
}
