import { HttpParams } from '@angular/common/http'
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core'

import { environment } from '../../../../environments/environment'
import { Role } from '../../../common/enums/role.enum'
import { Sample } from '../../../common/interfaces/sample.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { ViewportService } from '../../../common/services/viewport.service'
import { AuthService } from '../../../common/services/auth.service'
import { User } from '../../../common/interfaces/user.interface'

@Component({
  selector: 'app-sample-table',
  templateUrl: './sample-table.component.html',
  styleUrls: ['./sample-table.component.scss'],
  providers: [ResourceService]
})
export class SampleTableComponent implements OnInit {
  @Input() samples: Sample[]
  @Input() showMission = true
  sampleToDelete: Sample

  selectedSampleIds: number[] = []

  showDownloadButton: boolean
  showDownloadMenu: boolean
  activeActionDropdown: number
  activeTouchActionBar: number
  isTouchResolution: boolean
  currentUser: User

  storagePath: string = environment.storagePath
  Role = Role

  constructor(
    private resourceService: ResourceService,
    private viewportService: ViewportService,
    private authService: AuthService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.viewportService.isTouchResolution.subscribe(
      (isTouchResolution: boolean) => {
        this.isTouchResolution = isTouchResolution
      }
    )

    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })
  }

  toggleSelect(sampleId: number) {
    if (this.selectedSampleIds.includes(sampleId)) {
      const index = this.selectedSampleIds.indexOf(sampleId)
      this.selectedSampleIds.splice(index, 1)
    } else {
      this.selectedSampleIds.push(sampleId)
    }

    // Display download button if sample(s) are selected
    this.showDownloadButton = !!this.selectedSampleIds.length
  }

  // Download Sample's Weighings file or ZIP folder with images
  download(type: string, sampleId?: number) {
    let params = new HttpParams()

    if (sampleId) {
      params = params.set('sampleIds', sampleId.toString())
    } else {
      this.selectedSampleIds.forEach((selectedSampleId: number) => {
        params = params.append('sampleIds', selectedSampleId.toString())
      })
    }

    if (type === 'images') {
      params = params.set('extractImages', 'true')
    } else if (type === 'weighings') {
      params = params.set('toFile', 'true')
    }

    this.resourceService
      .list('samples', params)
      .subscribe((exportRes: { filePath: string }) => {
        window.open(environment.storagePath + exportRes.filePath, '_blank')
      })
  }

  toggleActionDropdown(index: number) {
    if (this.activeActionDropdown === index) {
      delete this.activeActionDropdown
    } else {
      this.activeActionDropdown = index
    }
  }

  toggleTouchActionBar(index: number) {
    if (this.activeTouchActionBar === index) {
      delete this.activeTouchActionBar
    } else {
      this.activeTouchActionBar = index
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    // We track clicks to close dropdown if open and click outside
    if (this.activeActionDropdown >= 0) {
      const dropdowns: NodeList = this.elementRef.nativeElement.querySelectorAll(
        '.dropdown'
      )

      let clickedOut = true
      dropdowns.forEach((d: Node) => {
        if (d.contains(target)) {
          clickedOut = false
        }
      })

      if (clickedOut) {
        delete this.activeActionDropdown
      }
    }
  }
}
