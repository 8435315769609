import { Component, OnInit, ElementRef, HostListener } from '@angular/core'

import { User } from '../../common/interfaces/user.interface'
import { environment } from '../../../environments/environment'
import { AuthService } from '../../common/services/auth.service'
import { EventService } from '../../common/services/event.service'
import { Role } from '../../common/enums/role.enum'

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  currentUser: User
  showUserMenu: boolean
  showLinkMenu: boolean

  storagePath: string = environment.storagePath
  Role = Role

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })

    // CLose menus on changing route
    this.eventService.routeChanged.subscribe(routeChanged => {
      this.showLinkMenu = false
      this.showUserMenu = false
    })
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    // We track clicks to close dropdown if open and click outside
    if (
      !this.elementRef.nativeElement
        .querySelector('#create-dropdown')
        .contains(target)
    ) {
      this.showLinkMenu = false
      this.showUserMenu = false
    }
  }
}
