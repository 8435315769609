import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core'
import { AbcInput } from '../../../common/interfaces/abc-input.interface'
import { HTMLInputEvent } from '../../../common/interfaces/html-input-event.interface'
import { environment } from '../../../../environments/environment'
import { ValidatorFn, Validators, FormGroup, FormBuilder } from '@angular/forms'

@Component({
  selector: 'abc-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements AbcInput, OnInit {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() initialValue: { name: string; path: string }
  @Input() accept: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<{
    name: string
    content: File
  }> = new EventEmitter()

  @ViewChild('fileInput') fileInputEl: ElementRef

  file: { name: string; content: File }
  previouslyUploadedFile: { name: string; path: string }
  storagePath: string = environment.storagePath

  form: FormGroup
  required: boolean

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.initialValue) {
      this.previouslyUploadedFile = this.initialValue
    }

    this.form = this.formBuilder.group({
      file: [this.initialValue, this.validators || []]
    })
    this.required = this.validators.includes(Validators.required)
  }

  // Triggers on adding image
  fileInputEvent(fileInput: HTMLInputEvent) {
    this.file = {
      name: fileInput.target.files[0].name,
      content: this.fileInputEl.nativeElement.files.item(0)
    }

    this.form.get('file').setValue(this.file)
    this.valueChanged.emit(this.file)
  }

  removeFile() {
    delete this.file
    delete this.previouslyUploadedFile
    this.form.reset()
    this.valueChanged.emit(null)
  }
}
