import { HttpParams } from '@angular/common/http'
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core'
import * as fastLevenshtein from 'fast-levenshtein'

import { HTMLInputEvent } from '../../../common/interfaces/html-input-event.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-multi-search',
  templateUrl: './multi-search.component.html',
  styleUrls: ['./multi-search.component.scss'],
  providers: [ResourceService]
})
export class MultiSearchComponent implements OnChanges {
  @ViewChild('searchInput') searchInputEl: ElementRef

  @Input() resources: string[]
  @Input() placeholder: string
  @Input() maxSelectedItems = 500
  @Input() readonly = false
  @Input() closeListOnSelect = true
  @Input() initialSearchResults: {
    userIds?: string[]
    categoryIds?: string[]
    subcategoryIds?: string[]
  }
  @Input() extraHttpParams: { key: string; value: string }[] = []

  @Output() selectedSearchResultsChanged: EventEmitter<SearchResult[]> =
    new EventEmitter()

  suggestedSearchResults: SearchResult[] = []
  selectedSearchResults: SearchResult[] = []

  terms = ''
  showList = false
  focusedItemIndex: number

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private resourceService: ResourceService
  ) {}

  ngOnChanges() {
    this.getSearchResultObjects()
  }

  getSearchResultObjects() {
    // We retrieve ids from query params and call a service to fetch full objects with labels to display them by default

    let params = new HttpParams()

    if (this.initialSearchResults) {
      if (this.initialSearchResults.userIds) {
        this.initialSearchResults.userIds.forEach((id: string) => {
          params = params.append('userIds', id)
        })
      }
      if (this.initialSearchResults.categoryIds) {
        this.initialSearchResults.categoryIds.forEach((id: string) => {
          params = params.append('categoryIds', id)
        })
      }
      if (this.initialSearchResults.subcategoryIds) {
        this.initialSearchResults.subcategoryIds.forEach((id: string) => {
          params = params.append('subcategoryIds', id)
        })
      }

      this.resourceService
        .list('search/get-search-result-objects', params)
        .subscribe((initialSearchResultRes) => {
          this.selectedSearchResults = initialSearchResultRes
        })
    }
  }

  toggleItem(item: any): void {
    if (!this.readonly) {
      // Check if there already is an item with same id on selection
      if (this.selectedSearchResults.find((i: any) => i.id === item.id)) {
        this.selectedSearchResults.splice(
          this.selectedSearchResults.indexOf(item),
          1
        )
        this.renderer.setProperty(this.searchInputEl.nativeElement, 'value', '')
      } else if (this.selectedSearchResults.length < this.maxSelectedItems) {
        this.selectedSearchResults.push(item)
        this.renderer.setProperty(this.searchInputEl.nativeElement, 'value', '')
      }
      this.selectedSearchResultsChanged.emit(this.selectedSearchResults)

      if (this.closeListOnSelect) {
        this.showList = false
      }
    }
  }

  onSearchInputKeyup(event: HTMLInputEvent) {
    if (!this.readonly) {
      this.terms = event.target.value
      this.showList = true

      // Navigate through results
      if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
        return this.navigateSuggestedValues(event.key)
      }

      // Call remote service
      let params = new HttpParams()
      params = params.set('terms', this.terms)
      this.resources.forEach((resource: string) => {
        params = params.append('resources', resource)
      })

      if (this.extraHttpParams.length) {
        this.extraHttpParams.forEach((extraHttpParam) => {
          params = params.append(extraHttpParam.key, extraHttpParam.value)
        })
      }

      this.resourceService
        .list('search', params)
        .subscribe((searchResultsRes: SearchResult[]) => {
          // Sort by Levenshtein distance and limit array
          this.suggestedSearchResults = searchResultsRes
            .sort(
              (a: SearchResult, b: SearchResult) =>
                fastLevenshtein.get(this.terms, a.shortLabel) -
                fastLevenshtein.get(this.terms, b.shortLabel)
            )
            .slice(0, 20)

          delete this.focusedItemIndex
        })
    }
  }

  // Use arrowKeys and enter to select suggested themes with keyboard
  navigateSuggestedValues(key: string): void {
    if (key === 'ArrowDown') {
      if (typeof this.focusedItemIndex === 'undefined') {
        this.showList = true
        this.focusedItemIndex = 0
      } else if (
        this.focusedItemIndex <
        this.suggestedSearchResults.length - 1
      ) {
        this.focusedItemIndex++
      }
    } else if (key === 'ArrowUp') {
      if (!this.focusedItemIndex) {
        this.showList = false
        delete this.focusedItemIndex
      } else {
        this.focusedItemIndex--
      }
    } else if (
      key === 'Enter' &&
      typeof this.focusedItemIndex !== 'undefined' &&
      this.suggestedSearchResults[this.focusedItemIndex]
    ) {
      this.toggleItem(this.suggestedSearchResults[this.focusedItemIndex])
      if (this.closeListOnSelect) {
        delete this.focusedItemIndex
      }
    }
  }

  // Click outside closes list
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (!this.elementRef.nativeElement.contains(eventTarget)) {
      this.showList = false
    }
  }
}
