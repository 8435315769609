import { HttpParams } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { forkJoin } from 'rxjs'

import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { DynamicField } from '../../../common/interfaces/dynamic-field.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { Granulometry } from '../../../common/interfaces/granulometry.interface'
import { Mission } from '../../../common/interfaces/mission.interface'
import { Sample } from '../../../common/interfaces/sample.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { Subcategory } from '../../../common/interfaces/subcategory.interface'
import { Tare } from '../../../common/interfaces/tare.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-mission-create-edit',
  templateUrl: './mission-create-edit.component.html',
  styleUrls: ['./mission-create-edit.component.scss']
})
export class MissionCreateEditComponent implements OnInit {
  mode: string
  mission: Mission
  subcategories: Subcategory[]
  loading: boolean
  submitLoading: boolean

  dynamicFields: DynamicField[] = []
  initialSearchResults: { subcategoryIds: string[] }

  storagePath: string = environment.storagePath
  requiredValidator: ValidatorFn[] = [Validators.required]

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    reportFile: [null, Validators.required],
    protocolFile: null,
    customer: ['', Validators.required],
    isVolumeRequired: [false, Validators.required],
    subcategoryIds: [[], Validators.required],
    tares: [],
    granulometries: [],
    fields: this.formBuilder.array([])
  })

  constructor(
    private resourceService: ResourceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    // Get subcategories
    let subcategoryListParams = new HttpParams()
    subcategoryListParams = subcategoryListParams.set(
      'withoutPagination',
      'true'
    )

    this.resourceService
      .list('subcategories', subcategoryListParams)
      .subscribe((subcategoryRes: Subcategory[]) => {
        this.subcategories = subcategoryRes
      })

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/missions',
          label: 'Missions'
        },
        {
          label: 'Nouvelle mission'
        }
      ])

      // Add default dynamic fields
      const formArray: FormArray = this.form.get('fields') as FormArray
      appConstants.DEFAULT_DYNAMIC_FIELDS.forEach((field: DynamicField) => {
        formArray.push(this.formBuilder.group(field))
      })

      this.form.get('reportFile').setValidators(Validators.required)
    } else {
      this.loading = true
      let sampleParams: HttpParams = new HttpParams()
      sampleParams = sampleParams.set(
        'missionsIds',
        this.activatedRoute.snapshot.params.id
      )
      sampleParams = sampleParams.set('withoutPagination', 'true')

      forkJoin([
        this.resourceService.show(
          'missions',
          this.activatedRoute.snapshot.params.id
        ),
        this.resourceService.list('samples', sampleParams)
      ]).subscribe(
        ([missionRes, sampleRes]: [Mission, Sample[]]) => {
          this.loading = false
          this.mission = missionRes
          this.mission.samples = sampleRes

          this.form.controls.name.setValue(missionRes.name)
          this.form.controls.customer.setValue(missionRes.customer)
          this.form.controls.isVolumeRequired.setValue(
            missionRes.isVolumeRequired
          )
          this.form.controls.tares.setValue(
            missionRes.tares.map((t: Tare) => ({
              display: t.weight,
              value: t.weight
            }))
          )
          this.form.controls.granulometries.setValue(
            missionRes.granulometries.map((g: Granulometry) => ({
              display: g.name,
              value: g.name,
              editOnly: !!g.weighingCount
            }))
          )
          const subcategoryIdStrings: string[] = missionRes.subcategories.map(
            (sC: Subcategory) => sC.id.toString()
          )
          this.form.controls.subcategoryIds.setValue(subcategoryIdStrings)
          this.initialSearchResults = {
            subcategoryIds: subcategoryIdStrings
          }

          this.form.controls.reportFile.setValue(missionRes.reportFile)
          this.form.controls.protocolFile.setValue(missionRes.protocolFile)

          const formArray: FormArray = this.form.get('fields') as FormArray
          missionRes.fields.forEach((field: Field) => {
            const fieldObject: DynamicField = {
              id: field.id,
              name: field.name,
              required: field.required,
              editOnly: !!field.answerCount
            }
            formArray.push(this.formBuilder.group(fieldObject))
            // We store it in array to adapt UI based on editOnly prop
            this.dynamicFields.push(fieldObject)
          })

          this.breadcrumbService.breadcrumbLinks.next([
            {
              path: '/missions',
              label: 'Missions'
            },
            {
              path: '/missions/' + this.mission.id,
              label: this.mission.name
            },
            {
              label: 'Editer'
            }
          ])
        },
        (error) => {
          this.loading = false
          this.flashMessagesService.show(
            'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    }
  }

  submit(form: FormGroup) {
    this.submitLoading = true

    const formData = new FormData()
    formData.append('name', form.value.name)
    formData.append('customer', form.value.customer)
    formData.append('isVolumeRequired', form.value.isVolumeRequired ? '1' : '0')

    if (
      this.form.value.reportFile &&
      this.form.value.reportFile.name &&
      this.form.value.reportFile.content
    ) {
      formData.append(
        'reportFile',
        this.form.value.reportFile.content,
        this.form.value.reportFile.name
      )
    }
    if (
      this.form.value.protocolFile &&
      this.form.value.protocolFile.name &&
      this.form.value.protocolFile.content
    ) {
      formData.append(
        'protocolFile',
        this.form.value.protocolFile.content,
        this.form.value.protocolFile.name
      )
    }

    // For optional input (protocol file) if we removed file, we send empty value
    if (!this.form.value.protocolFile) {
      formData.append('protocolFile', '')
    }

    if (form.value.tares) {
      form.value.tares.forEach((tare: { display: string; value: string }) => {
        formData.append(
          this.mode === 'create' ? 'taresToCreate' : 'taresToSync',
          tare.value
        )
      })
    }
    if (form.value.granulometries) {
      form.value.granulometries.forEach(
        (granulometry: {
          display: string
          value: string
          readonly: boolean
        }) => {
          // We don't send "readonly" Granulometries (with Weighings) because they will not be updated
          if (!granulometry.readonly) {
            formData.append(
              this.mode === 'create'
                ? 'granulometriesToCreate'
                : 'granulometriesToSync',
              granulometry.value
            )
          }
        }
      )
    }

    if (form.value.fields) {
      const fields: DynamicField[] = form.value.fields.filter(
        (f: DynamicField) => f.name && !f.editOnly
      )
      fields.forEach((field: DynamicField) => {
        formData.append(
          this.mode === 'create' ? 'fieldsToCreate' : 'fieldsToSync',
          JSON.stringify(field)
        )
      })

      if (this.mode === 'edit') {
        const answeredFieldsToUpdate: DynamicField[] = form.value.fields.filter(
          (f: DynamicField) => f.editOnly && f.id
        )
        answeredFieldsToUpdate.forEach((field: DynamicField) => {
          formData.append('answeredFieldsToUpdate', JSON.stringify(field))
        })
      }
    }

    form.value.subcategoryIds.forEach((subcategoryId: number) => {
      formData.append('subcategoryIds', subcategoryId.toString())
    })

    if (this.mode === 'create') {
      this.resourceService.store('missions', formData).subscribe(
        (createdMission: Mission) => {
          this.submitLoading = false
          this.flashMessagesService.show(`La mission a bien été créé`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          this.router.navigate(['/missions', createdMission.id])
        },
        (err) => {
          this.submitLoading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Update existing resource
      this.resourceService
        .update('missions', this.mission.id, formData)
        .subscribe(
          (res) => {
            this.submitLoading = false
            this.flashMessagesService.show(`La mission a bien été mis à jour`, {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            })
            this.router.navigate(['/missions', this.mission.id])
          },
          (err) => {
            this.submitLoading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map((m) => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  addDynamicField() {
    const formArray: FormArray = this.form.get('fields') as FormArray
    formArray.push(this.formBuilder.group({ name: '', required: false }))
  }

  removeDynamicField(index: number) {
    const formArray: FormArray = this.form.get('fields') as FormArray
    formArray.removeAt(index)
  }

  onSelectedSearchResultsChanged(searchResults: SearchResult[]) {
    this.form
      .get('subcategoryIds')
      .setValue(searchResults.map((s: SearchResult) => s.id.toString()))
  }

  setFile(fileEvent: { name: string; content: File }, propName: string) {
    this.form.get(propName).setValue(fileEvent)
  }
}
