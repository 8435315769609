import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpParams } from '@angular/common/http'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { FormBuilder, FormGroup } from '@angular/forms'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { ViewportService } from '../../../common/services/viewport.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { AuthService } from '../../../common/services/auth.service'
import { User } from '../../../common/interfaces/user.interface'
import { Role } from '../../../common/enums/role.enum'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-mission-list',
  templateUrl: './mission-list.component.html',
  styleUrls: ['./mission-list.component.scss'],
  providers: [ResourceService]
})
export class MissionListComponent implements OnInit {
  paginator: Paginator
  filterForm: FormGroup = this.formBuilder.group({
    missionIds: []
  })
  initialSearchResults: { missionIds: string[] } = {
    missionIds: []
  }
  isTouchResolution: boolean
  currentUser: User
  Role = Role
  loading: boolean

  constructor(
    private resourceService: ResourceService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private viewportService: ViewportService,
    private flashMessagesService: FlashMessagesService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Missions'
      }
    ])
  }

  ngOnInit() {
    this.viewportService.isTouchResolution.subscribe(
      (isTouchResolution: boolean) => {
        this.isTouchResolution = isTouchResolution
      }
    )

    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })

    this.activatedRoute.queryParams.subscribe(queryParams => {
      let params = new HttpParams()
      params = params.set('page', queryParams.page ? queryParams.page : 1)

      // Missions
      if (queryParams.missionIds && queryParams.missionIds.length) {
        this.initialSearchResults.missionIds = []

        // If only one item (string), we convert it into a single-item array
        const missionIds =
          typeof queryParams.missionIds === 'string'
            ? [queryParams.missionIds]
            : queryParams.missionIds
        missionIds.forEach((missionId: string) => {
          params = params.append('missionIds', missionId)
          this.initialSearchResults.missionIds.push(missionId)
          this.filterForm.get('missionIds').setValue(missionIds)
        })
      }

      this.loading = true
      this.resourceService.list('missions', params).subscribe(
        (missionRes: Paginator) => {
          this.loading = false
          this.paginator = missionRes
        },
        error => {
          this.loading = false
          this.flashMessagesService.show(
            'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    })

    // Reload component on changing filterForm
    this.filterForm.valueChanges.subscribe(params => {
      this.router.navigate(['/missions'], {
        queryParams: params,
        queryParamsHandling: 'merge'
      })
    })
  }

  onSelectedSearchResultsChanged(searchResults: SearchResult[]) {
    this.filterForm
      .get('missionIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'missions')
          .map((s: SearchResult) => s.id.toString())
      )
  }
}
