import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Paginator } from '../../../common/interfaces/paginator.interface'
import { HttpParams } from '@angular/common/http'
import { FormGroup, FormBuilder } from '@angular/forms'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.scss'],
  providers: [ResourceService]
})
export class SubcategoryListComponent implements OnInit {
  paginator: Paginator
  filterForm: FormGroup = this.formBuilder.group({
    categoryIds: [],
    subcategoryIds: []
  })
  loading: boolean

  initialSearchResults: { subcategoryIds: string[]; categoryIds: string[] } = {
    subcategoryIds: [],
    categoryIds: []
  }

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Sous-catégories'
      }
    ])
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      let params = new HttpParams()
      params = params.set('page', queryParams.page ? queryParams.page : 1)

      // Subcategories
      if (queryParams.subcategoryIds && queryParams.subcategoryIds.length) {
        this.initialSearchResults.subcategoryIds = []

        // If only one item (string), we convert it into a single-item array
        const subcategoryIds =
          typeof queryParams.subcategoryIds === 'string'
            ? [queryParams.subcategoryIds]
            : queryParams.subcategoryIds
        subcategoryIds.forEach((subcategoryId: string) => {
          params = params.append('subcategoryIds', subcategoryId)
          this.initialSearchResults.subcategoryIds.push(subcategoryId)
          this.filterForm.get('subcategoryIds').setValue(subcategoryIds)
        })
      }

      // Categories
      if (queryParams.categoryIds && queryParams.categoryIds.length) {
        this.initialSearchResults.categoryIds = []

        // If only one item (string), we convert it into a single-item array
        const categoryIds =
          typeof queryParams.categoryIds === 'string'
            ? [queryParams.categoryIds]
            : queryParams.categoryIds
        categoryIds.forEach((categoryId: string) => {
          params = params.append('categoryIds', categoryId)
          this.initialSearchResults.categoryIds.push(categoryId)
          this.filterForm.get('categoryIds').setValue(categoryIds)
        })
      }

      this.loading = true
      this.resourceService.list('subcategories', params).subscribe(
        (subcategoryRes: Paginator) => {
          this.loading = false
          this.paginator = subcategoryRes
        },
        error => {
          this.loading = false
          this.flashMessagesService.show(
            'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    })

    // Reload component on changing filterForm
    this.filterForm.valueChanges.subscribe(params => {
      this.router.navigate(['/subcategories'], {
        queryParams: params,
        queryParamsHandling: 'merge'
      })
    })
  }

  onSelectedSearchResultsChanged(searchResults: SearchResult[]) {
    this.filterForm
      .get('subcategoryIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'subcategories')
          .map((s: SearchResult) => s.id.toString())
      )

    this.filterForm
      .get('categoryIds')
      .setValue(
        searchResults
          .filter((s: SearchResult) => s.resourceName === 'categories')
          .map((s: SearchResult) => s.id.toString())
      )
  }
}
