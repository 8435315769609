import { Component, Input } from '@angular/core'
import { User } from '../../../common/interfaces/user.interface'
import { environment } from '../../../../environments/environment'
import { ResourceService } from '../../../common/services/resource.service'
import { Role } from '../../../common/enums/role.enum'

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  providers: [ResourceService]
})
export class UserTableComponent {
  @Input() users: User[]

  Role = Role
  userToDelete: User
  storagePath: string = environment.storagePath

  constructor(private resourceService: ResourceService) {}

  toggleActive(user: User) {
    this.resourceService
      .patch('users', user.id, 'toggle-active', new FormData())
      .subscribe(() => {
        user.active = !user.active
      })
  }

  delete(user: User): void {
    this.userToDelete = user
  }

  onDeleteCanceled() {
    delete this.userToDelete
  }
}
