import { Component, Input } from '@angular/core'
import { Category } from '../../../common/interfaces/category.interface'

@Component({
  selector: 'app-category-table',
  templateUrl: './category-table.component.html',
  styleUrls: ['./category-table.component.scss']
})
export class CategoryTableComponent {
  @Input() categories: Category[]
  categoryToDelete: Category
}
