import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  ViewChild,
  Input,
  Renderer2
} from '@angular/core'
import { TweenLite, Power2 } from 'gsap'

import { User } from '../../common/interfaces/user.interface'
import { environment } from '../../../environments/environment'
import { AuthService } from '../../common/services/auth.service'
import { EventService } from '../../common/services/event.service'
import { Role } from '../../common/enums/role.enum'
import { QuickLinkService } from '../../common/services/quick-link.service'
import * as moment from 'moment'

@Component({
  selector: 'app-touch-menu',
  templateUrl: './touch-menu.component.html',
  styleUrls: ['./touch-menu.component.scss']
})
export class TouchMenuComponent implements OnInit {
  @Input() isLogin: boolean
  @ViewChild('menu') menuEl: ElementRef
  @ViewChild('hidingLayer') hidingLayerEl: ElementRef
  @ViewChild('menuItems') menuItemsEl: ElementRef
  showMenu: boolean
  currentUser: User
  showBurgerMenu: boolean
  backButton: { path: string; label: string }
  quickLink: string

  storagePath: string = environment.storagePath
  Role = Role
  sampleDateFrom: string
  sampleDateTo: string = moment().format('YYYY-MM-DD')

  private isSampleDetailRegExp = new RegExp('/samples/[0-9]+$')
  private isSampleEditRegExp = new RegExp('/samples/[0-9]+/edit')
  private isWeighingCreateRegExp = new RegExp(
    '/samples/[0-9]+/weighings/create'
  )

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private elementRef: ElementRef,
    private quickLinkService: QuickLinkService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes

      if (this.currentUser.role === Role.Admin) {
        this.sampleDateFrom = moment()
          .subtract(1, 'week')
          .format('YYYY-MM-DD')
      } else {
        this.sampleDateFrom = moment()
          .startOf('month')
          .format('YYYY-MM-DD')
      }
    })

    // Display burger menu or "back button" based on route
    this.eventService.routeChanged.subscribe(
      (routeChanged: { url: string }) => {
        if (this.isSampleDetailRegExp.test(routeChanged.url)) {
          this.showBurgerMenu = false
          this.backButton = {
            path: '/samples',
            label: 'Prélèvements'
          }
        } else if (this.isSampleEditRegExp.test(routeChanged.url)) {
          this.showBurgerMenu = false
          this.backButton = {
            path: routeChanged.url.substr(0, routeChanged.url.indexOf('/edit')),
            label: 'Retour'
          }
        } else if (this.isWeighingCreateRegExp.test(routeChanged.url)) {
          this.showBurgerMenu = false
          this.backButton = {
            path: routeChanged.url.substr(
              0,
              routeChanged.url.indexOf('/weighings/create')
            ),
            label: 'Prélèvement'
          }
        } else {
          this.showBurgerMenu = true
          delete this.quickLink
        }
      }
    )

    this.quickLinkService.quickLink.subscribe((newValue: string) => {
      this.quickLink = newValue
    })
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu
    this.triggerMenuAnimation()
  }

  closeMenu(): void {
    this.showMenu = false
    this.triggerMenuAnimation()
  }

  triggerMenuAnimation() {
    if (this.showMenu) {
      TweenLite.set(this.menuEl.nativeElement, {
        className: '+=is-open'
      })

      TweenLite.to(this.menuItemsEl.nativeElement, 0.5, {
        opacity: 1,
        left: '0',
        ease: Power2.easeInOut
      })
      TweenLite.to(this.hidingLayerEl.nativeElement, 0.5, {
        autoAlpha: 0.9,
        ease: Power2.easeInOut
      })
      this.renderer.addClass(document.querySelector('html'), 'is-clipped')
    } else {
      TweenLite.set(this.menuEl.nativeElement, {
        className: '-=is-open'
      })

      TweenLite.to(this.menuItemsEl.nativeElement, 0.5, {
        opacity: 0.8,
        left: '-261px',
        ease: Power2.easeInOut,
        delay: 0.1
      })
      TweenLite.to(this.hidingLayerEl.nativeElement, 0.5, {
        autoAlpha: 0,
        ease: Power2.easeInOut
      })

      this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    // Click on hiding layer closes menu
    if (
      this.elementRef.nativeElement
        .querySelector('.hiding-layer')
        .contains(target) &&
      !this.elementRef.nativeElement
        .querySelector('.navbar-menu')
        .contains(target) &&
      this.elementRef.nativeElement.querySelector('.navbar-burger') &&
      !this.elementRef.nativeElement
        .querySelector('.navbar-burger')
        .contains(target)
    ) {
      this.closeMenu()
    }
  }
}
