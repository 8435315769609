import {
  Component,
  Input,
  Renderer2,
  OnChanges,
  EventEmitter,
  Output
} from '@angular/core'
import { ResourceService } from '../../common/services/resource.service'
import { FlashMessagesService } from 'angular2-flash-messages'
import { Router } from '@angular/router'
import { appConstants } from '../../app.constants'

@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent implements OnChanges {
  @Input() resourceToDelete: any
  @Input() resourceName: string
  @Input() navigateTo: string

  @Output() deleteCanceled: EventEmitter<void> = new EventEmitter()

  showModal: boolean

  constructor(
    private resourceService: ResourceService,
    private flashMessagesService: FlashMessagesService,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnChanges() {
    this.showModal = true
    this.renderer.addClass(document.querySelector('html'), 'is-clipped')
  }

  confirmDelete() {
    this.resourceService
      .delete(this.resourceName, this.resourceToDelete.id)
      .subscribe(
        res => {
          this.closeModal()
          // Change query params to force reload on lists
          this.router.navigate(
            [this.navigateTo ? this.navigateTo : this.resourceName],
            {
              queryParams: { reload: new Date().toISOString() }
            }
          )
          this.flashMessagesService.show(`La ressource a bien été supprimée`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
        },
        err => {
          this.closeModal()
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
  }

  closeModal() {
    this.showModal = false
    this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
  }

  cancel() {
    this.deleteCanceled.emit()
    this.closeModal()
  }
}
