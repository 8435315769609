import {
  CurrencyPipe,
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData
} from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { JwtModule } from '@auth0/angular-jwt'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { FlashMessagesModule } from 'angular2-flash-messages'
import { MyDatePickerModule } from 'mydatepicker'
import { TagInputModule } from 'ngx-chips'

import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthService } from './common/services/auth.service'
import { BreadcrumbService } from './common/services/breadcrumb.service'
import { EventService } from './common/services/event.service'
import { QuickLinkService } from './common/services/quick-link.service'
import { SampleService } from './common/services/sample.service'
import { ViewportService } from './common/services/viewport.service'
import { FileInputComponent } from './elements/inputs/file-input/file-input.component'
import { ImageInputComponent } from './elements/inputs/image-input/image-input.component'
import { Error404Component } from './errors/error404/error404.component'
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component'
import { HomeComponent } from './pages/home/home.component'
import { BreadcrumbsComponent } from './partials/breadcrumbs/breadcrumbs.component'
import { ConfirmDeleteModalComponent } from './partials/confirm-delete-modal/confirm-delete-modal.component'
import { MultiSearchComponent } from './partials/inputs/multi-search/multi-search.component'
import { MetaComponent } from './partials/meta/meta.component'
import { NetworkNotificationComponent } from './partials/network-notification/network-notification.component'
import { PaginationComponent } from './partials/pagination/pagination.component'
import { SideMenuComponent } from './partials/side-menu/side-menu.component'
import { TopMenuComponent } from './partials/top-menu/top-menu.component'
import { TouchMenuComponent } from './partials/touch-menu/touch-menu.component'
import { CategoryCreateEditComponent } from './resources/category/category-create-edit/category-create-edit.component'
import { CategoryListComponent } from './resources/category/category-list/category-list.component'
import { CategoryTableComponent } from './resources/category/category-table/category-table.component'
import { MissionCreateEditComponent } from './resources/mission/mission-create-edit/mission-create-edit.component'
import { MissionDetailComponent } from './resources/mission/mission-detail/mission-detail.component'
import { MissionListComponent } from './resources/mission/mission-list/mission-list.component'
import { MissionTableTouchComponent } from './resources/mission/mission-table-touch/mission-table-touch.component'
import { MissionTableComponent } from './resources/mission/mission-table/mission-table.component'
import { SampleCreateEditComponent } from './resources/sample/sample-create-edit/sample-create-edit.component'
import { SampleDetailComponent } from './resources/sample/sample-detail/sample-detail.component'
import { SampleListComponent } from './resources/sample/sample-list/sample-list.component'
import { SamplePreCreateComponent } from './resources/sample/sample-pre-create/sample-pre-create.component'
import { SampleTableComponent } from './resources/sample/sample-table/sample-table.component'
import { SubcategoryCreateEditComponent } from './resources/subcategory/subcategory-create-edit/subcategory-create-edit.component'
import { SubcategoryListComponent } from './resources/subcategory/subcategory-list/subcategory-list.component'
import { SubcategoryTableComponent } from './resources/subcategory/subcategory-table/subcategory-table.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { UserListComponent } from './resources/user/user-list/user-list.component'
import { UserTableComponent } from './resources/user/user-table/user-table.component'
import { WeighingCreateEditComponent } from './resources/weighing/weighing-create-edit/weighing-create-edit.component'
import { WeighingTableComponent } from './resources/weighing/weighing-table/weighing-table.component'

// Register locale data
registerLocaleData(localeFr, 'fr')

if (environment.enableBugsnag) {
  Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    releaseStage: environment.envName
  })
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

export function jwtTokenGetter() {
  return localStorage.getItem('token')
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LoginComponent,
    HomeComponent,
    SideMenuComponent,
    TopMenuComponent,
    UserListComponent,
    UserCreateEditComponent,
    UserTableComponent,
    MetaComponent,
    PaginationComponent,
    ConfirmDeleteModalComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    BreadcrumbsComponent,
    MultiSearchComponent,
    MissionListComponent,
    MissionTableComponent,
    MissionCreateEditComponent,
    CategoryListComponent,
    CategoryTableComponent,
    CategoryCreateEditComponent,
    SubcategoryListComponent,
    SubcategoryTableComponent,
    SubcategoryCreateEditComponent,
    WeighingCreateEditComponent,
    SampleListComponent,
    SampleTableComponent,
    SampleDetailComponent,
    Error404Component,
    TouchMenuComponent,
    MissionDetailComponent,
    SampleCreateEditComponent,
    MissionTableTouchComponent,
    WeighingTableComponent,
    SamplePreCreateComponent,
    FileInputComponent,
    ImageInputComponent,
    NetworkNotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TagInputModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MyDatePickerModule,
    FlashMessagesModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: environment.tokenWhitelistedDomains
      }
    })
  ],
  providers: [
    AuthService,
    EventService,
    BreadcrumbService,
    QuickLinkService,
    ViewportService,
    SampleService,
    { provide: LOCALE_ID, useValue: 'fr' },
    CurrencyPipe,
    { provide: 'Window', useValue: window },
    {
      provide: LocationStrategy,
      useClass: environment.nativescriptEmbedded
        ? HashLocationStrategy
        : PathLocationStrategy
    },
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
