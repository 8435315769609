import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef
} from '@angular/core'
import { Mission } from '../../../common/interfaces/mission.interface'
import { Sample } from '../../../common/interfaces/sample.interface'
import { User } from '../../../common/interfaces/user.interface'
import { Role } from '../../../common/enums/role.enum'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-mission-table',
  templateUrl: './mission-table.component.html',
  styleUrls: ['./mission-table.component.scss']
})
export class MissionTableComponent implements OnInit {
  @Input() missions: Mission[]
  missionToDelete: Mission

  activeActionDropdown: number

  currentUser: User
  Role = Role

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })

    this.missions.forEach((m: Mission) => {
      m.weighingCount = m.samples.reduce(
        (previous: number, current: Sample) => previous + current.weighingCount,
        0
      )
    })
  }

  toggleActionDropdown(index: number) {
    if (this.activeActionDropdown === index) {
      delete this.activeActionDropdown
    } else {
      this.activeActionDropdown = index
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    // We track clicks to close dropdown if open and click outside
    if (this.activeActionDropdown >= 0) {
      const dropdowns: NodeList = this.elementRef.nativeElement.querySelectorAll(
        '.dropdown'
      )

      let clickedOut = true
      dropdowns.forEach((d: Node) => {
        if (d.contains(target)) {
          clickedOut = false
        }
      })

      if (clickedOut) {
        delete this.activeActionDropdown
      }
    }
  }
}
