import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges
} from '@angular/core'
import { AbcInput } from '../../../common/interfaces/abc-input.interface'
import { HTMLInputEvent } from '../../../common/interfaces/html-input-event.interface'
import { environment } from '../../../../environments/environment'
import { ValidatorFn, Validators, FormGroup, FormBuilder } from '@angular/forms'
import { ImageSet } from '../../../common/interfaces/image-set.interface'
import { Observable } from 'rxjs'

@Component({
  selector: 'abc-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss']
})
export class ImageInputComponent implements AbcInput, OnInit, OnChanges {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() initialValue: ImageSet
  @Input() nativeFileUploaded = false
  @Input() accept: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() reset: Observable<void>

  @Output() valueChanged: EventEmitter<{
    name: string
    content: File
  }> = new EventEmitter()

  @ViewChild('imageInput') imageInputEl: ElementRef

  image: { name: string; content: File }
  previouslyUploadedImage: ImageSet
  storagePath: string = environment.storagePath

  form: FormGroup
  required: boolean

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.initialValue) {
      this.previouslyUploadedImage = this.initialValue
    }

    // Subscribe to observable if exists. It allows to call removeFile from the parent component.
    if (this.reset) {
      this.reset.subscribe(() => this.removeFile())
    }
  }

  ngOnChanges(changes) {
    this.form = this.formBuilder.group({
      image: [this.initialValue, this.validators || []]
    })
    if (!this.initialValue && !this.nativeFileUploaded) {
      this.removeFile()
    }
    if (this.nativeFileUploaded) {
      this.image = { name: 'Image importée', content: null }
    }

    this.required = this.validators.includes(Validators.required)
  }

  // Triggers on adding image
  imageInputEvent(imageInput: HTMLInputEvent) {
    this.image = {
      name: imageInput.target.files[0].name,
      content: this.imageInputEl.nativeElement.files.item(0)
    }

    this.form.get('image').setValue(this.image)
    this.initialValue = { changed: true }
    this.valueChanged.emit(this.image)
  }

  removeFile() {
    delete this.image
    delete this.previouslyUploadedImage
    this.nativeFileUploaded = false
    this.form.reset()
    this.valueChanged.emit(null)
  }
}
