export const appConstants = {
  FLASH_MESSAGE_TIMEOUT: 4000,
  TOUCH_BREAKPOINT: 999,

  DEFAULT_DYNAMIC_FIELDS: [
    {
      name: 'Météo',
      required: true
    },
    {
      name: 'Pression atmosphérique',
      required: false
    }
  ]
}
