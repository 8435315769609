import { HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Component, ElementRef, HostListener, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { ViewportService } from 'src/app/common/services/viewport.service'

import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { Sample } from '../../../common/interfaces/sample.interface'
import { Weighing } from '../../../common/interfaces/weighing.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { QuickLinkService } from '../../../common/services/quick-link.service'
import { ResourceService } from '../../../common/services/resource.service'
import { SampleService } from '../../../common/services/sample.service'

@Component({
  selector: 'app-sample-detail',
  templateUrl: './sample-detail.component.html',
  styleUrls: ['./sample-detail.component.scss'],
  providers: [ResourceService]
})
export class SampleDetailComponent implements OnInit {
  sample: Sample
  weighings: Weighing[]
  weightPerGranulometry: { name: string; weight: number }[] = []
  totalWeightPerGranulometry: number
  totalWeightWeighed = 0

  showDownloadMenu: boolean
  storagePath: string = environment.storagePath
  isTouchResolution: boolean
  loading: boolean

  constructor(
    private resourceService: ResourceService,
    private sampleService: SampleService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private quickLinkService: QuickLinkService,
    private elementRef: ElementRef,
    private viewportService: ViewportService,
    private flashMessagesService: FlashMessagesService,
    private router: Router
  ) {}

  ngOnInit() {
    // Subscribe to query params to reload data when a weighing is deleted
    this.activatedRoute.queryParams.subscribe(() => {
      this.getSample(this.activatedRoute.snapshot.params as { id: string })
    })
    this.viewportService.isTouchResolution.subscribe(
      (isTouchResolution: boolean) => {
        this.isTouchResolution = isTouchResolution
      }
    )
  }

  // Load sample resource
  getSample(params: { id: string }): void {
    this.loading = true
    this.sampleService.show(params.id).subscribe(
      async (sampleRes: Sample) => {
        this.sample = sampleRes

        this.loading = false

        // We get Weighing after getting the Sample data for performance (we display Sample data while loading weighings).
        this.weighings = await this.resourceService
          .list(
            'weighings',
            new HttpParams().set('sampleId', this.sample.id.toString())
          )
          .toPromise()
          .then((weighingRes: Weighing[]) => weighingRes)
          .catch((err) => {
            return []
          })

        // Calculate weight per granulometry
        this.weightPerGranulometry = []
        this.weighings.forEach((w: Weighing) => {
          if (w.granulometry) {
            const granulometry: {
              name: string
              weight: number
            } = this.weightPerGranulometry.find(
              (wPG) => wPG.name === w.granulometry.name
            )

            if (!granulometry) {
              this.weightPerGranulometry.push({
                name: w.granulometry.name,
                weight: w.weight - w.tare
              })
            } else {
              granulometry.weight += w.weight - w.tare
            }
          }
          this.totalWeightWeighed += w.weight - w.tare
        })

        this.totalWeightPerGranulometry = this.weightPerGranulometry.reduce(
          (sum: number, g: { name: string; weight: number }) => sum + g.weight,
          0
        )

        this.breadcrumbService.breadcrumbLinks.next([
          {
            path: '/samples',
            label: 'Prélèvements'
          },
          {
            path: '/samples/' + this.sample.id,
            label: this.sample.name
          }
        ])
        this.quickLinkService.quickLink.next(this.sample.mission.protocolFile)
      },
      (error: HttpErrorResponse) => {
        this.loading = false

        if (error.status === 404) {
          this.router.navigate(['/404'])
        }

        this.flashMessagesService.show(
          'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
          {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
      }
    )
  }

  // Download Sample's Weighings file or ZIP folder with images
  download(type: string) {
    let params = new HttpParams()
    params = params.set('sampleIds', this.sample.id.toString())

    if (type === 'images') {
      params = params.set('extractImages', 'true')
    } else if (type === 'weighings') {
      params = params.set('toFile', 'true')
    }

    this.resourceService
      .list('samples', params)
      .subscribe((exportRes: { filePath: string }) => {
        window.open(environment.storagePath + exportRes.filePath, '_blank')
      })
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    // We track clicks to close dropdown if open and click outside
    if (this.showDownloadMenu) {
      const dropdowns: NodeList =
        this.elementRef.nativeElement.querySelectorAll('.dropdown')

      let clickedOut = true
      dropdowns.forEach((d: Node) => {
        if (d.contains(target)) {
          clickedOut = false
        }
      })

      if (clickedOut) {
        delete this.showDownloadMenu
      }
    }
  }
}
