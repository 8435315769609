import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { HomeComponent } from './pages/home/home.component'
import { AuthGuard } from './common/guards/auth.guard'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { IsAdminGuard } from './common/guards/is-admin.guard'
import { UserListComponent } from './resources/user/user-list/user-list.component'
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component'
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component'
import { MissionListComponent } from './resources/mission/mission-list/mission-list.component'
import { MissionCreateEditComponent } from './resources/mission/mission-create-edit/mission-create-edit.component'
import { CategoryListComponent } from './resources/category/category-list/category-list.component'
import { CategoryCreateEditComponent } from './resources/category/category-create-edit/category-create-edit.component'
import { SubcategoryListComponent } from './resources/subcategory/subcategory-list/subcategory-list.component'
import { SubcategoryCreateEditComponent } from './resources/subcategory/subcategory-create-edit/subcategory-create-edit.component'
import { SampleDetailComponent } from './resources/sample/sample-detail/sample-detail.component'
import { WeighingCreateEditComponent } from './resources/weighing/weighing-create-edit/weighing-create-edit.component'
import { Error404Component } from './errors/error404/error404.component'
import { SampleListComponent } from './resources/sample/sample-list/sample-list.component'
import { SampleCreateEditComponent } from './resources/sample/sample-create-edit/sample-create-edit.component'
import { MissionDetailComponent } from './resources/mission/mission-detail/mission-detail.component'
import { SamplePreCreateComponent } from './resources/sample/sample-pre-create/sample-pre-create.component'
import { environment } from '../environments/environment'

const routes: Routes = [
  // Auth
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },

  // Pages
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },

  // Users
  {
    path: 'users',
    component: UserListComponent,
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'users/create',
    component: UserCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'users/:id/edit',
    component: UserCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },

  // Missions
  {
    path: 'missions',
    component: MissionListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'missions/create',
    component: MissionCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'missions/:id',
    component: MissionDetailComponent,
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'missions/:id/edit',
    component: MissionCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'missions/:missionId/samples/create',
    component: SampleCreateEditComponent,
    data: { mode: 'createFromMission' },
    canActivate: [AuthGuard]
  },

  // Categories
  {
    path: 'categories',
    component: CategoryListComponent,
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'categories/create',
    component: CategoryCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'categories/:id/edit',
    component: CategoryCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },

  // SubCategories
  {
    path: 'subcategories',
    component: SubcategoryListComponent,
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'subcategories/create',
    component: SubcategoryCreateEditComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard, IsAdminGuard]
  },
  {
    path: 'subcategories/:id/edit',
    component: SubcategoryCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard, IsAdminGuard]
  },

  // Samples
  {
    path: 'samples',
    component: SampleListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'samples/create',
    component: SamplePreCreateComponent,
    data: { mode: 'create' },
    canActivate: [AuthGuard]
  },
  {
    path: 'samples/:id',
    component: SampleDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'samples/:id/edit',
    component: SampleCreateEditComponent,
    data: { mode: 'edit' },
    canActivate: [AuthGuard]
  },
  {
    path: 'samples/:sampleId/weighings/create',
    component: WeighingCreateEditComponent,
    data: { mode: 'createFromSample' },
    canActivate: [AuthGuard]
  },
  {
    path: 'samples/:sampleId/weighings/:weighingId/edit',
    component: WeighingCreateEditComponent,
    data: { mode: 'editFromSample' },
    canActivate: [AuthGuard]
  },

  // Other
  {
    path: '404',
    component: Error404Component,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: environment.nativescriptEmbedded })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
