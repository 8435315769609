import { HttpErrorResponse, HttpParams } from '@angular/common/http'
import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core'
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { fromEvent, Subject } from 'rxjs'
import { isNull } from 'util'

import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { Sample } from '../../../common/interfaces/sample.interface'
import { SearchResult } from '../../../common/interfaces/search-result.interface'
import { Weighing } from '../../../common/interfaces/weighing.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { NativescriptInterfaceService } from '../../../common/services/nativescript-interface.service'
import { QuickLinkService } from '../../../common/services/quick-link.service'
import { ResourceService } from '../../../common/services/resource.service'
import { SampleService } from '../../../common/services/sample.service'

@Component({
  selector: 'app-weighing-create-edit',
  templateUrl: './weighing-create-edit.component.html',
  styleUrls: ['./weighing-create-edit.component.scss'],
  providers: [ResourceService]
})
export class WeighingCreateEditComponent implements OnInit, OnDestroy {
  @ViewChild('tareHelpSelect') tareHelpSelectEl: ElementRef
  sample: Sample
  weighings: Weighing[]
  weighing: Weighing

  mode: string
  isOverweight: boolean
  isNegativeWeight: boolean
  isNativescriptEmbedded: boolean
  isOnline = window.navigator.onLine

  enableWeightEdit = false
  submitLoading: boolean
  requiredValidator: ValidatorFn[] = [Validators.required]
  initialSearchResults: { subcategoryIds: string[] }
  resetImageInput: Subject<void> = new Subject<void>()

  form: FormGroup = this.formBuilder.group({
    weight: [null, Validators.required],
    elementCount: [1, Validators.required],
    sampleId: [null, Validators.required],
    subcategoryId: [null, Validators.required],
    tare: null,
    volume: null,
    comments: null,
    granulometryId: null,
    isWeightManual: false,
    image: [null, Validators.required],
    acceptOverweight: false
  })

  // Embedded app only
  nativeFileUploaded = false

  constructor(
    private resourceService: ResourceService,
    private sampleService: SampleService,
    private router: Router,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private breadcrumbService: BreadcrumbService,
    private quickLinkService: QuickLinkService,
    private nativescriptInterfaceService: NativescriptInterfaceService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.watchConnectionStatus()

    this.mode = this.activatedRoute.snapshot.data.mode

    this.activatedRoute.params.subscribe(
      async (params: { sampleId: string; weighingId?: string }) => {
        this.sampleService
          .show(params.sampleId)
          .subscribe(async (sampleRes: Sample) => {
            this.sample = sampleRes

            // We get Weighing after getting the Sample data for performance (we display Sample data while loading weighings).
            this.weighings = await this.resourceService
              .list(
                'weighings',
                new HttpParams().set('sampleId', this.sample.id.toString())
              )
              .toPromise()
              .then((weighingRes: Weighing[]) => weighingRes)
              .catch(() => [])

            // Calculate total weighing weights
            this.sample.totalWeighingNetWeights = this.weighings.reduce(
              (previous: number, currentWeighing: Weighing) =>
                previous + (currentWeighing.weight - currentWeighing.tare),
              0
            )

            this.form.get('sampleId').setValue(this.sample.id)

            // If Mission has defined Granulometries then it's mandatory to choose one
            if (this.sample.mission.granulometries.length) {
              this.form.get('granulometryId').setValidators(Validators.required)
            }

            if (this.sample.mission.isVolumeRequired) {
              this.form.get('volume').setValidators(Validators.required)
            }

            this.quickLinkService.quickLink.next(this.sample.mission.reportFile)

            if (this.mode === 'createFromSample') {
              this.breadcrumbService.breadcrumbLinks.next([
                {
                  path: '/samples',
                  label: 'Prélèvements'
                },
                {
                  path: '/samples/' + this.sample.id,
                  label: this.sample.name
                },
                {
                  label: 'Nouvelle pesée'
                }
              ])
            } else {
              this.resourceService
                .show('weighings', params.weighingId)
                .subscribe((weighingRes: Weighing) => {
                  this.weighing = weighingRes

                  this.form.patchValue({
                    weight: this.weighing.weight,
                    elementCount: this.weighing.elementCount,
                    sampleId: this.weighing.sample.id,
                    subcategoryId: this.weighing.subcategory.id,
                    tare: this.weighing.tare,
                    volume: this.weighing.volume,
                    comments: this.weighing.comments,
                    granulometryId: this.weighing.granulometry
                      ? this.weighing.granulometry.id
                      : null,
                    isWeightManual: this.weighing.isWeightManual,
                    image: this.weighing.image
                  })

                  this.initialSearchResults = {
                    subcategoryIds: [this.weighing.subcategory.id.toString()]
                  }

                  this.breadcrumbService.breadcrumbLinks.next([
                    {
                      path: '/samples',
                      label: 'Prélèvements'
                    },
                    {
                      path: '/samples/' + this.sample.id,
                      label: this.sample.name
                    },
                    {
                      label: 'Editer pesée'
                    }
                  ])
                })
            }

            // Open communication with balance to get Bluetooth weight (embedded app)
            if (environment.nativescriptEmbedded) {
              this.isNativescriptEmbedded = true
              this.openBalanceCommunication()
            }
          })
      }
    )

    this.form.valueChanges.subscribe(
      (formValues: { tare: number; weight: number }) => {
        if (formValues.weight) {
          // Calculate if overweight
          this.isOverweight =
            formValues.weight &&
            this.sample &&
            this.sample.totalWeighingNetWeights +
              formValues.weight -
              formValues.tare >
              this.sample.weight - this.sample.tare

          // Calculate if weight negative
          this.isNegativeWeight =
            formValues.weight && formValues.weight < formValues.tare
        }
      }
    )
  }

  ngOnDestroy() {
    if (environment.nativescriptEmbedded) {
      this.nativescriptInterfaceService.closeBalanceCommunication()
    }
  }

  submit(form: FormGroup, createAnotherOneAfter: boolean): void {
    this.submitLoading = true
    const formData = new FormData()
    formData.append('weight', form.value.weight)
    formData.append('elementCount', form.value.elementCount)
    formData.append('sampleId', form.value.sampleId)
    formData.append('subcategoryId', form.value.subcategoryId)
    formData.append('isWeightManual', form.value.isWeightManual ? '1' : '0')

    if (form.value.image && form.value.image.content && form.value.image.name) {
      formData.append('image', form.value.image.content, form.value.image.name)
    }

    // Optional
    if (form.value.granulometryId) {
      formData.append('granulometryId', form.value.granulometryId)
    }
    if (form.value.tare) {
      formData.append('tare', form.value.tare)
    }
    if (form.value.volume) {
      formData.append('volume', form.value.volume)
    }
    if (form.value.comments) {
      formData.append('comments', form.value.comments)
    }

    if (this.mode === 'createFromSample') {
      /*
       * For a better offline management, we don't wait for network reestablishment to finish the subscription.
       * Instead, we handle "no network" errors by warning the user and sending another request on the background,
       * this time a request that retries when connection is available.
       */
      this.resourceService
        .storeAndFailIfOffline('weighings', formData)
        .subscribe(
          (createdSample: Sample) => {
            this.submitLoading = false
            this.flashMessagesService.show(`La pesée a bien été enregistrée`, {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            })
            if (createAnotherOneAfter) {
              this.resetForm()
            } else {
              this.router.navigate(['/samples', this.sample.id])
            }
          },
          (err: HttpErrorResponse) => {
            this.submitLoading = false
            if (err.status === 0 && !this.isOnline) {
              if (createAnotherOneAfter) {
                this.resetForm()
              } else {
                this.router.navigate(['/samples', this.sample.id])
              }
              this.resourceService
                .store('weighings', formData)
                .toPromise()
                .then(() => {
                  this.flashMessagesService.show(
                    `La pesée préalablement créée sans connexion a bien été enregistrée.`,
                    {
                      cssClass: 'notification is-success',
                      timeout: appConstants.FLASH_MESSAGE_TIMEOUT
                    }
                  )
                })
              // If "no network error" we keep progression (OfflineInterceptor will send request on network connection on background).
              this.flashMessagesService.show(
                `Pas de réseau: La pesée a été prise en compte et sera envoyée dès le rétablissement du réseau.`,
                {
                  cssClass: 'notification is-blue',
                  timeout: appConstants.FLASH_MESSAGE_TIMEOUT
                }
              )
            } else {
              this.flashMessagesService.show(
                'Error ' +
                  (err && err.error && err.error.message
                    ? JSON.stringify(
                        err.error.message.map((m) => m.constraints)
                      )
                    : `Une erreur est survenue durant l'envoi du formulaire`),
                {
                  cssClass: 'notification is-danger',
                  timeout: appConstants.FLASH_MESSAGE_TIMEOUT
                }
              )
            }
          }
        )
    } else {
      // Update existing resource
      this.resourceService
        .update('weighings', this.weighing.id, formData)
        .subscribe(
          (res) => {
            this.submitLoading = false
            this.flashMessagesService.show(`La pesée a bien été mise à jour`, {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            })
            this.router.navigate(['/samples', this.sample.id])
          },
          (err) => {
            this.submitLoading = false
            this.flashMessagesService.show(
              'Erreur : ' +
                (err && err.error && err.error.message
                  ? JSON.stringify(err.error.message.map((m) => m.constraints))
                  : `Une erreur est survenue durant l'envoi du formulaire`),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  toggleEnableWeightEdit() {
    this.enableWeightEdit = !this.enableWeightEdit

    if (this.enableWeightEdit) {
      this.form.get('isWeightManual').setValue(true)
    } else {
      this.form.get('isWeightManual').setValue(false)
      this.form.get('weight').setValue('')
    }
  }

  setTare(newValue: number) {
    this.form.get('tare').setValue(newValue)
  }

  // Triggers on adding image
  setImage(imageEvent: { name: string; content: File | any }) {
    this.form.get('image').setValue(imageEvent)

    if (isNull(imageEvent)) {
      this.nativeFileUploaded = false
    }
  }

  onSelectedSearchResultsChanged(searchResults: SearchResult[]) {
    this.form
      .get('subcategoryId')
      .setValue(
        searchResults && searchResults.length
          ? searchResults[0].id.toString()
          : null
      )
  }

  resetForm() {
    this.resetImageInput.next()
    this.enableWeightEdit = false
    this.nativeFileUploaded = false
    this.form.patchValue({
      granulometryId: null,
      subcategoryId: null,
      weight: null,
      volume: null,
      sampleId: this.sample.id,
      elementCount: 1,
      tare: '',
      image: null,
      isWeightManual: false,
      comments: null
    })
    this.initialSearchResults = { subcategoryIds: [] }

    if (this.tareHelpSelectEl) {
      this.renderer.setProperty(
        this.tareHelpSelectEl.nativeElement,
        'value',
        ''
      )
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  makeThatBlink() {
    this.renderer.addClass(document.querySelector('#weight-disabled'), 'blink')
    setTimeout(() => {
      this.renderer.removeClass(
        document.querySelector('#weight-disabled'),
        'blink'
      )
    }, 1200)
  }

  watchConnectionStatus() {
    fromEvent(window, 'offline').subscribe((e) => {
      this.isOnline = false
    })

    fromEvent(window, 'online').subscribe((e) => {
      this.isOnline = true
    })
  }

  openBalanceCommunication() {
    console.log('open balance communication')
    this.nativescriptInterfaceService.openBalanceCommunication()
    this.defineInterfaceFunctions()
  }

  defineInterfaceFunctions() {
    window.setFileToUpload = (base64file: string) => {
      console.log('>> Set File To Upload')
      const blob = this.setImage({
        name: 'Image from Native',
        content: this.nativescriptInterfaceService.base64toBlob(
          base64file,
          'image/jpg'
        )
      })
      this.ngZone.run(() => {
        this.nativeFileUploaded = true
      })
    }

    window.setWeight = (weight: string) => {
      let newWeight: number = parseFloat(weight)
      newWeight = isNaN(newWeight) ? 0 : Number(newWeight.toFixed(3))

      console.log('>> New weight', newWeight)

      if (!this.enableWeightEdit) {
        if (newWeight !== this.form.value.weight) {
          this.ngZone.run(() => {
            this.makeThatBlink()
          })
        }
        this.form.get('weight').setValue(newWeight)
        this.form.get('isWeightManual').setValue(false)
      }
    }
  }
}
