import {
  Component,
  Input,
  HostListener,
  ElementRef,
  OnInit
} from '@angular/core'
import { fromEvent } from 'rxjs'
import { Weighing } from '../../../common/interfaces/weighing.interface'
import { ViewportService } from '../../../common/services/viewport.service'

@Component({
  selector: 'app-weighing-table',
  templateUrl: './weighing-table.component.html',
  styleUrls: ['./weighing-table.component.scss']
})
export class WeighingTableComponent implements OnInit {
  @Input() weighings: Weighing[]
  @Input() sampleId: number

  weighingToDelete: Weighing
  activeActionDropdown: number
  activeTouchActionBar: number

  isTouchResolution: boolean
  isOnline = window.navigator.onLine

  constructor(
    private elementRef: ElementRef,
    private viewportService: ViewportService
  ) {}

  ngOnInit() {
    this.viewportService.isTouchResolution.subscribe(
      (isTouchResolution: boolean) => {
        this.isTouchResolution = isTouchResolution
      }
    )

    fromEvent(window, 'offline').subscribe((e) => {
      this.isOnline = false
    })

    fromEvent(window, 'online').subscribe((e) => {
      this.isOnline = true
    })
  }

  toggleActionDropdown(index: number) {
    if (this.activeActionDropdown === index) {
      delete this.activeActionDropdown
    } else {
      this.activeActionDropdown = index
    }
  }

  toggleTouchActionBar(index: number) {
    if (this.activeTouchActionBar === index) {
      delete this.activeTouchActionBar
    } else {
      this.activeTouchActionBar = index
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    // We track clicks to close dropdown if open and click outside
    if (this.activeActionDropdown >= 0) {
      const dropdowns: NodeList =
        this.elementRef.nativeElement.querySelectorAll('.dropdown')

      let clickedOut = true
      dropdowns.forEach((d: Node) => {
        if (d.contains(target)) {
          clickedOut = false
        }
      })

      if (clickedOut) {
        delete this.activeActionDropdown
      }
    }
  }

  delete(weighing: Weighing): void {
    this.weighingToDelete = weighing
  }

  onDeleteCanceled() {
    delete this.weighingToDelete
  }
}
