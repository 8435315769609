import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard {
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return localStorage.getItem('role') === 'Admin'
  }
}
