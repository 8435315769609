import { Component, OnInit } from '@angular/core'

import { User } from '../../common/interfaces/user.interface'
import { AuthService } from '../../common/services/auth.service'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentUser: User

  constructor(
    private authService: AuthService,
    breadcrumbService: BreadcrumbService
  ) {
    breadcrumbService.breadcrumbLinks.next([])
  }

  ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })
  }
}
