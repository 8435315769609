import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription } from 'rxjs'

import { environment } from '../environments/environment'
import { appConstants } from './app.constants'
import { User } from './common/interfaces/user.interface'
import { AuthService } from './common/services/auth.service'
import { EventService } from './common/services/event.service'
import { NativescriptInterfaceService } from './common/services/nativescript-interface.service'
import { ViewportService } from './common/services/viewport.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./styles/main.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  currentUser: User
  path: string
  isLogin: boolean

  private eventSubscriptions = new Subscription()
  private subscription = new Subscription()

  isTouchResolution: boolean

  constructor(
    private router: Router,
    private eventService: EventService,
    private authService: AuthService,
    private viewportService: ViewportService,
    private nativescriptInterfaceService: NativescriptInterfaceService
  ) {
    this.eventSubscriptions.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.eventService.routeChanged.next({ url: event.url })
        }
      })
    )
  }

  ngOnInit() {
    this.setIsTouchResolution()
    this.viewportService.isTouchResolution.subscribe(
      (isTouchResolution: boolean) => {
        this.isTouchResolution = isTouchResolution
      }
    )
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })
    this.subscription.add(
      this.eventService.routeChanged.subscribe(routeChanged => {
        // Scroll top
        window.scrollTo(0, 0)
        this.path = routeChanged.url.includes('?')
          ? routeChanged.url.substring(0, routeChanged.url.indexOf('?'))
          : routeChanged.url
        this.isLogin =
          this.path.includes('/login') ||
          this.path.includes('forgot-password') ||
          this.path.includes('reset-password')

        if (this.authService.check() && !this.currentUser) {
          this.authService.me().subscribe(
            (userRes: User) => {
              this.authService.currentUser.next(userRes)
            },
            err => {
              this.router.navigate(['/logout'])
            }
          )
        }
      })
    )

    if (environment.nativescriptEmbedded) {
      this.nativescriptInterfaceService.initInterface()
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.setIsTouchResolution()
  }

  setIsTouchResolution(): void {
    this.viewportService.isTouchResolution.next(
      window.innerWidth < appConstants.TOUCH_BREAKPOINT
    )
  }
}
