import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { Role } from '../enums/role.enum'
import { User } from '../interfaces/user.interface'

@Injectable()
export class AuthService {
  token: string
  role: string
  baseUrl = environment.apiBaseUrl + 'auth/'

  public currentUser = new ReplaySubject(1)

  constructor(private http: HttpClient, private router: Router) {
    // Set token if saved in local storage
    this.token = localStorage.getItem('token')
    this.role = localStorage.getItem('role')
  }

  login(
    email: string,
    password: string
  ): Observable<{ role: string; userId: number } | boolean> {
    return this.http.post(this.baseUrl + 'login', { email, password }).pipe(
      map(
        (res: { accessToken: string; role: string; userId: number }) => {
          const token = res && res.accessToken
          const role = res && res.role
          this.token = token
          this.role = role
          // Store JWT token and Role in local storage
          localStorage.setItem('token', token)
          localStorage.setItem('role', role)

          return { role: this.role, userId: res.userId }
        },
        err => err.error.message
      )
    )
  }

  logout(): void {
    this.token = null
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    this.currentUser.next(null)
  }

  check(): boolean {
    return !!this.getToken()
  }

  getToken(): string {
    return localStorage.getItem('token')
  }

  getRole(): Role {
    if (environment.nativescriptEmbedded) {
      // Embedded app considers everyone as simple User.
      return Role.User
    }
    return Role[localStorage.getItem('role')] as Role
  }

  me(): Observable<User | boolean> {
    return this.http.get(this.baseUrl + 'me').pipe(map((res: User) => res))
  }

  sendResetPasswordEmail(email: string) {
    let params = new HttpParams()
    params = params.set('email', email)

    return this.http
      .get(this.baseUrl + 'forgot-password', { params })
      .pipe(map((res: any) => res))
  }

  resetPassword(newPassword: string, token: string): Observable<any> {
    const formData = new FormData()
    formData.append('newPassword', newPassword)
    formData.append('token', token)

    return this.http
      .post(this.baseUrl + 'reset-password', formData)
      .pipe(map((res: any) => res))
  }
}
