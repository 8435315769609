import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { Router, ActivatedRoute } from '@angular/router'
import { Mission } from '../../../common/interfaces/mission.interface'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ValidatorFn
} from '@angular/forms'
import { HttpParams } from '@angular/common/http'
import { HasDatepicker } from '../../../common/base-components/has-datepicker'
import { Field } from '../../../common/interfaces/field.interface'
import { FlashMessagesService } from 'angular2-flash-messages'
import { appConstants } from '../../../app.constants'
import { Sample } from '../../../common/interfaces/sample.interface'
import { Answer } from '../../../common/interfaces/answer.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../common/services/auth.service'
import { User } from '../../../common/interfaces/user.interface'
import { Role } from '../../../common/enums/role.enum'
import { NativescriptInterfaceService } from '../../../common/services/nativescript-interface.service'
import { isNull } from 'util'

@Component({
  selector: 'app-sample-create-edit',
  templateUrl: './sample-create-edit.component.html',
  styleUrls: ['./sample-create-edit.component.scss'],
  providers: [ResourceService]
})
export class SampleCreateEditComponent extends HasDatepicker implements OnInit {
  mission: Mission
  sample: Sample
  users: User[]
  mode: string

  netWeight: number
  dynamicFieldIds: number[] = []
  storagePath: string = environment.storagePath
  requiredValidator: ValidatorFn[] = [Validators.required]

  currentUser: User
  Role = Role
  loading: boolean
  submitLoading: boolean

  // Only for embedded version
  clickedImageInput: string
  // On native version, files are not directly uploaded through input so we keep track of adding to reproduce the similar interaction.
  nativeFilesUploaded = {
    image1: false,
    image2: false,
    image3: false,
    image4: false,
    image5: false,
    image6: false,
    image7: false,
    image8: false
  }

  form: FormGroup = this.formBuilder.group({
    missionId: [null, Validators.required],
    name: ['', Validators.required],
    receiptDate: [null],
    origin: ['', Validators.required],
    weight: [null, Validators.required],
    tare: '',
    image1: [null, Validators.required],
    image2: [null, Validators.required],
    image3: [null, Validators.required],
    image4: null,
    image5: null,
    image6: null,
    image7: null,
    image8: null,
    userId: ''
  })

  constructor(
    private resourceService: ResourceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private breadcrumbService: BreadcrumbService,
    private nativescriptInterfaceService: NativescriptInterfaceService,
    private authService: AuthService,
    private ngZone: NgZone
  ) {
    super()
  }

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes

      this.activatedRoute.params.subscribe(
        (params: { missionId?: string; id?: string }) => {
          let userListParams = new HttpParams()
          userListParams = userListParams.set('withoutPagination', 'true')

          this.loading = true
          this.resourceService
            .list('users', userListParams)
            .subscribe((userListRes: User[]) => {
              this.users = userListRes

              let listParams = new HttpParams()
              listParams = listParams.append('withoutPagination', 'true')
              if (this.mode === 'createFromMission') {
                this.resourceService
                  .list('missions', listParams)
                  .subscribe((missionRes: Mission[]) => {
                    this.loading = false
                    this.form.get('missionId').setValue(params.missionId)
                    this.mission = missionRes.find(
                      (m: Mission) => m.id === parseInt(params.missionId, 10)
                    )
                    this.setDynamicFields(this.mission)

                    this.breadcrumbService.breadcrumbLinks.next([
                      {
                        path: '/missions',
                        label: 'Missions'
                      },
                      {
                        path: '/missions/' + this.mission.id,
                        label: this.mission.name
                      },
                      {
                        label: 'Nouveau prélèvement'
                      }
                    ])
                  })
              } else if (this.mode === 'edit') {
                this.resourceService
                  .show('samples', params.id)
                  .subscribe((sampleRes: Sample) => {
                    this.loading = false

                    this.sample = sampleRes
                    this.mission = this.sample.mission
                    this.setDynamicFields(this.mission)

                    // Append data into form
                    this.form.get('missionId').setValue(sampleRes.mission.id)
                    this.form.get('name').setValue(sampleRes.name)
                    this.form.get('origin').setValue(sampleRes.origin)
                    this.form.get('weight').setValue(sampleRes.weight)

                    this.form.get('image1').setValue(sampleRes.image1)
                    this.form.get('image2').setValue(sampleRes.image2)
                    this.form.get('image3').setValue(sampleRes.image3)
                    this.form.get('image4').setValue(sampleRes.image4)
                    this.form.get('image5').setValue(sampleRes.image5)
                    this.form.get('image6').setValue(sampleRes.image6)
                    this.form.get('image7').setValue(sampleRes.image7)
                    this.form.get('image8').setValue(sampleRes.image8)

                    this.form.get('userId').setValue(sampleRes.user.id)

                    if (sampleRes.tare) {
                      this.form.get('tare').setValue(sampleRes.tare)
                    }
                    if (sampleRes.receiptDate) {
                      this.form
                        .get('receiptDate')
                        .setValue(
                          this.formatStandardDate(sampleRes.receiptDate)
                        )
                    }

                    sampleRes.answers.forEach((a: Answer) => {
                      const control = this.form.get(
                        'field' + a.field.id.toString()
                      )
                      if (control) {
                        control.setValue(a.value)
                      }
                    })

                    this.breadcrumbService.breadcrumbLinks.next([
                      {
                        path: '/samples',
                        label: 'Prélèvements'
                      },
                      {
                        path: '/samples/' + sampleRes.id,
                        label: sampleRes.name
                      },
                      {
                        label: 'Editer'
                      }
                    ])
                  })
              }
            })

          // Calculate live net weight
          this.form.valueChanges.subscribe(
            (formValues: { tare: number; weight: number }) => {
              if (formValues.weight) {
                this.netWeight =
                  Math.round(
                    (formValues.weight - (formValues.tare || 0)) * 100
                  ) / 100
              }
            }
          )
        },
        (error) => {
          this.loading = false
          this.flashMessagesService.show(
            'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    })

    // Define native interface functions to get image files from camera/filepicker on embedded version
    if (environment.nativescriptEmbedded) {
      this.defineInterfaceFunctions()
    }
  }

  submit(form: FormGroup): void {
    this.submitLoading = true

    const formData = new FormData()
    formData.append('missionId', form.value.missionId)
    formData.append('name', form.value.name)
    formData.append('origin', form.value.origin)
    formData.append('weight', form.value.weight)

    formData.append(
      'receiptDate',
      form.value.receiptDate
        ? this.formatMyDatePickerDate(form.value.receiptDate)
        : ''
    )
    formData.append('tare', form.value.tare || 0)

    if (form.value.userId && this.currentUser.role === Role.Admin) {
      formData.append('userId', form.value.userId)
    }

    for (let index = 1; index <= 8; index++) {
      const imageName = 'image' + index
      if (
        form.value[imageName] &&
        form.value[imageName].content &&
        form.value[imageName].name
      ) {
        formData.append(
          imageName,
          form.value[imageName].content,
          form.value[imageName].name
        )
      }

      // For optional images , if we removed image, we send empty value
      if (!form.value[imageName] && index >= 4) {
        formData.append(imageName, '')
      }
    }

    // Answers to dynamicFields
    if (this.dynamicFieldIds.length) {
      const answerObjects: { fieldId: number; value: string }[] = []
      this.dynamicFieldIds.forEach((fieldId: number) => {
        if (form.value['field' + fieldId]) {
          answerObjects.push({
            fieldId,
            value: form.value['field' + fieldId]
          })
        }
      })

      formData.append('answerObjects', JSON.stringify(answerObjects))
    }

    if (this.mode === 'create' || this.mode === 'createFromMission') {
      this.resourceService.store('samples', formData).subscribe(
        (createdSample: Sample) => {
          this.submitLoading = false
          this.flashMessagesService.show(`Le prélèvement a bien été créé`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          this.router.navigate(['/samples', createdSample.id])
        },
        (err) => {
          this.submitLoading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map((m) => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Update existing resource
      this.resourceService
        .update('samples', this.sample.id, formData)
        .subscribe(
          (res) => {
            this.submitLoading = false
            this.flashMessagesService.show(
              `Le prélèvement a bien été mis à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
            this.router.navigate(['/samples', this.sample.id])
          },
          (err) => {
            this.submitLoading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map((m) => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  setDynamicFields(mission: Mission) {
    // Set dynamic fields
    mission.fields.forEach((f: Field) => {
      const controlName = 'field' + f.id.toString()
      const validators = f.required ? [Validators.required] : []

      this.form.addControl(controlName, new FormControl('', validators))
      this.dynamicFieldIds.push(f.id)
    })
  }

  // Triggers on adding image
  setImage(
    imageEvent: { name: string; content: File | any },
    propName: string
  ) {
    this.form.get(propName).setValue(imageEvent)

    if (isNull(imageEvent)) {
      this.nativeFilesUploaded[propName] = false
    }
  }

  defineInterfaceFunctions() {
    window.setFileToUpload = (base64file: string) => {
      const blob = this.setImage(
        {
          name: 'Image from Native',
          content: this.nativescriptInterfaceService.base64toBlob(
            base64file,
            'image/jpg'
          )
        },

        this.clickedImageInput
      )
      this.ngZone.run(() => {
        this.nativeFilesUploaded[this.clickedImageInput] = true
        delete this.clickedImageInput
      })
    }
  }
}
