import { Injectable } from '@angular/core'
import { NSWebViewinterface } from '../../../assets/libs/nativescript-webview-interface.js'

// declare window interface for nativeScript interface
declare global {
  interface Window {
    nsWebViewInterface: {
      emit: (eventName: string, value: any) => void
    }
    setWeight: (weight: string) => void
    setFileToUpload: (base64file: string) => void
    ng: any
  }
}

@Injectable({
  providedIn: 'root'
})
export class NativescriptInterfaceService {
  /*
   * On embedded version, we use Nativescript Webview interface https://www.npmjs.com/package/nativescript-webview-interface
   * to communicate from the native app to the embedded webview.
   * The native app connects to Bluetooth 3 and native file storage / camera and sends values to webview
   */

  initInterface() {
    console.log('>> initInterface')
    window.nsWebViewInterface = new NSWebViewinterface()
    window.nsWebViewInterface.emit('initInterface', true)
  }

  openBalanceCommunication() {
    console.log('>> Open Balance communication')
    window.nsWebViewInterface.emit('openBalanceCommunication', true)
  }

  closeBalanceCommunication() {
    console.log('>> Close Balance communication')
    window.nsWebViewInterface.emit('closeBalanceCommunication', true)
  }

  // Converts base64 into blog. Useful for receiving images from native app.
  base64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }
}
