import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'

import { appConstants } from '../../../app.constants'
import { AuthService } from '../../../common/services/auth.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    password: ['', Validators.required],
    token: ['', Validators.required]
  })

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private flashMessagesService: FlashMessagesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.form.controls.token.setValue(
      this.activatedRoute.snapshot.queryParams.token
    )
  }

  submit() {
    this.authService
      .resetPassword(this.form.value.password, this.form.value.token)
      .subscribe(
        res => {
          this.flashMessagesService.show(
            `Votre mot de passe a bien été réinitialisé, veuillez vous connecter à l'application`,
            {
              cssClass: 'notification is-success',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )

          return this.router.navigate(['/login'])
        },
        err => {
          this.flashMessagesService.show('err.error.message', {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          this.form.reset()
        }
      )
  }
}
