export const environment = {
  production: true,
  envName: 'staging',
  nativescriptEmbedded: false,
  apiBaseUrl: 'https://preprod.api.caracterra.terra.coop/',
  storagePath: 'https://preprod.api.caracterra.terra.coop/storage/',
  tokenWhitelistedDomains: ['preprod.api.caracterra.terra.coop'],
  enableBugsnag: false,
  bugsnagApiKey: '81b419fa4ab9b5b35247485b088692ca'
}
