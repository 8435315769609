import { HttpParams } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FlashMessagesService } from 'angular2-flash-messages'
import { combineLatest, forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../../environments/environment'
import { appConstants } from '../../../app.constants'
import { Granulometry } from '../../../common/interfaces/granulometry.interface'
import { Mission } from '../../../common/interfaces/mission.interface'
import { Sample } from '../../../common/interfaces/sample.interface'
import { Tare } from '../../../common/interfaces/tare.interface'
import { User } from '../../../common/interfaces/user.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'

@Component({
  selector: 'app-mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss'],
  providers: [ResourceService]
})
export class MissionDetailComponent implements OnInit {
  mission: Mission
  users: User[] = []
  tareToDelete: Tare
  granulometryToDelete: Granulometry
  storagePath: string = environment.storagePath
  loading: boolean

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    // Subscribe to params and query params. QueryParams subscription reload Mission on tare/granulometry change.
    combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
      .pipe(map((results) => ({ params: results[0], queryParams: results[1] })))
      .subscribe(
        (res: { params: { id: string }; queryParams: { reload: string } }) => {
          this.getMission(res.params.id)
        }
      )
  }

  getMission(id: string): void {
    this.loading = true

    let sampleParams: HttpParams = new HttpParams()
    sampleParams = sampleParams.set('missionIds', id)
    sampleParams = sampleParams.set('withoutPagination', 'true')
    forkJoin([
      this.resourceService.show('missions', id),
      this.resourceService.list('samples', sampleParams)
    ]).subscribe(
      ([missionRes, sampleRes]: [Mission, Sample[]]) => {
        this.loading = false
        this.mission = missionRes
        this.mission.samples = sampleRes
        this.mission.weighingCount = 0

        this.mission.samples.forEach((sample: Sample) => {
          // Get all User from mission sample
          if (!this.users.find((u) => u.id === sample.user.id)) {
            this.users.push(sample.user)
          }

          // Pass Mission prop to Sample as Mission not available in sample-table context
          sample.missionReportFile = this.mission.reportFile

          // Calculate total weighings
          this.mission.weighingCount =
            this.mission.weighingCount + sample.weighings.length
        })

        this.breadcrumbService.breadcrumbLinks.next([
          {
            path: '/missions',
            label: 'Missions'
          },
          {
            label: this.mission.name
          }
        ])
      },
      (error) => {
        this.loading = false
        this.flashMessagesService.show(
          'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
          {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
      }
    )
  }
}
