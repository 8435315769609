import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { FlashMessagesService } from 'angular2-flash-messages'
import { Category } from '../../../common/interfaces/category.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { appConstants } from '../../../app.constants'

@Component({
  selector: 'app-category-create-edit',
  templateUrl: './category-create-edit.component.html',
  styleUrls: ['./category-create-edit.component.scss']
})
export class CategoryCreateEditComponent implements OnInit {
  mode: string
  category: Category
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required]
  })
  loading: boolean
  submitLoading: boolean

  constructor(
    private resourceService: ResourceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    if (this.mode === 'create') {
      this.breadcrumbService.breadcrumbLinks.next([
        {
          path: '/categories',
          label: 'Catégories'
        },
        {
          label: 'Nouvelle catégorie'
        }
      ])
    } else {
      this.loading = true
      this.resourceService
        .show('categories', this.activatedRoute.snapshot.params.id)
        .subscribe(
          (categoryRes: Category) => {
            this.loading = false
            this.category = categoryRes

            this.form.controls.name.setValue(categoryRes.name)

            // Set breadcrumbs
            this.breadcrumbService.breadcrumbLinks.next([
              {
                path: '/categories',
                label: 'Catégories'
              },
              {
                label: this.category.name
              }
            ])
          },
          error => {
            this.loading = false
            this.flashMessagesService.show(
              'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }

  submit(form: FormGroup) {
    const formData = new FormData()
    formData.append('name', form.value.name)

    this.submitLoading = true

    if (this.mode === 'create') {
      this.resourceService.store('categories', formData).subscribe(
        res => {
          this.submitLoading = false
          this.flashMessagesService.show(`La catégorie a bien été créé`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          this.router.navigate(['/categories'])
        },
        err => {
          this.submitLoading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Update existing resource
      this.resourceService
        .update('categories', this.category.id, formData)
        .subscribe(
          res => {
            this.submitLoading = false
            this.flashMessagesService.show(
              `La catégorie a bien été mis à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
            this.router.navigate(['/categories'])
          },
          err => {
            this.submitLoading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map(m => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }
}
