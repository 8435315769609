import { Component, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { FlashMessagesService } from 'angular2-flash-messages'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { appConstants } from '../../../app.constants'
import { Subcategory } from '../../../common/interfaces/subcategory.interface'
import { Category } from '../../../common/interfaces/category.interface'
import { HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-subcategory-create-edit',
  templateUrl: './subcategory-create-edit.component.html',
  styleUrls: ['./subcategory-create-edit.component.scss']
})
export class SubcategoryCreateEditComponent implements OnInit {
  mode: string
  subcategory: Subcategory
  categories: Category[]
  loading: boolean
  submitLoading: boolean

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    categoryId: [null, Validators.required]
  })

  constructor(
    private resourceService: ResourceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.data.mode

    let categoryListParams = new HttpParams()
    categoryListParams = categoryListParams.set('withoutPagination', 'true')

    this.loading = true
    this.resourceService.list('categories', categoryListParams).subscribe(
      (categoryRes: Category[]) => {
        this.categories = categoryRes

        if (this.mode === 'create') {
          this.loading = false
          this.breadcrumbService.breadcrumbLinks.next([
            {
              path: '/subcategories',
              label: 'Sous-catégories'
            },
            {
              label: 'Nouvelle sous-catégorie'
            }
          ])
        } else {
          this.resourceService
            .show('subcategories', this.activatedRoute.snapshot.params.id)
            .subscribe(
              (subcategoryRes: Subcategory) => {
                this.loading = false
                this.subcategory = subcategoryRes

                this.form.controls.name.setValue(subcategoryRes.name)
                this.form.controls.categoryId.setValue(
                  subcategoryRes.category.id
                )

                // Set breadcrumbs
                this.breadcrumbService.breadcrumbLinks.next([
                  {
                    path: '/subcategories',
                    label: 'Sous-catégories'
                  },
                  {
                    label: this.subcategory.name
                  }
                ])
              },
              error => {
                this.loading = false
                this.flashMessagesService.show(
                  'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
                  {
                    cssClass: 'notification is-danger',
                    timeout: appConstants.FLASH_MESSAGE_TIMEOUT
                  }
                )
              }
            )
        }
      },
      error => {
        this.loading = false
        this.flashMessagesService.show(
          'Une erreur a eu lieu : Impossible de récupérer le(s) élémént(s) désiré(s).',
          {
            cssClass: 'notification is-danger',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )
      }
    )
  }

  submit(form: FormGroup) {
    const formData = new FormData()
    formData.append('name', form.value.name)

    this.submitLoading = true
    formData.append('categoryId', form.value.categoryId)

    if (this.mode === 'create') {
      this.resourceService.store('subcategories', formData).subscribe(
        res => {
          this.submitLoading = false
          this.flashMessagesService.show(`La catégorie a bien été créé`, {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          })
          this.router.navigate(['/subcategories'])
        },
        err => {
          this.submitLoading = false
          this.flashMessagesService.show(
            'Error ' +
              JSON.stringify(err.error.message.map(m => m.constraints)),
            {
              cssClass: 'notification is-danger',
              timeout: appConstants.FLASH_MESSAGE_TIMEOUT
            }
          )
        }
      )
    } else {
      // Update existing resource
      this.resourceService
        .update('subcategories', this.subcategory.id, formData)
        .subscribe(
          res => {
            this.submitLoading = false
            this.flashMessagesService.show(
              `La catégorie a bien été mis à jour`,
              {
                cssClass: 'notification is-success',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
            this.router.navigate(['/subcategories'])
          },
          err => {
            this.submitLoading = false
            this.flashMessagesService.show(
              'Error ' +
                JSON.stringify(err.error.message.map(m => m.constraints)),
              {
                cssClass: 'notification is-danger',
                timeout: appConstants.FLASH_MESSAGE_TIMEOUT
              }
            )
          }
        )
    }
  }
}
