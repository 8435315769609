import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthService } from '../../../common/services/auth.service'
import { Router } from '@angular/router'
import { appConstants } from '../../../app.constants'
import { FlashMessagesService } from 'angular2-flash-messages'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [AuthService]
})
export class ForgotPasswordComponent {
  form: FormGroup = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]]
  })

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private flashMessagesService: FlashMessagesService,
    private router: Router
  ) {}

  submit() {
    this.authService.sendResetPasswordEmail(this.form.value.email).subscribe(
      () => {
        this.flashMessagesService.show(
          'Un email avec un lien de réinitialisation vous a été envoyé. Veuillez consulter votre boite mail.',
          {
            cssClass: 'notification is-success',
            timeout: appConstants.FLASH_MESSAGE_TIMEOUT
          }
        )

        return this.router.navigate(['/'])
      },
      err => {
        this.flashMessagesService.show(err.error.message, {
          cssClass: 'notification is-danger',
          timeout: appConstants.FLASH_MESSAGE_TIMEOUT
        })
        this.form.reset()
      }
    )
  }
}
